import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FAQSection = () => {
  const faqData = [
    {
      question: "Why should I consider hiring an Infor consultant from Kapil?",
      answer: "Expert Infor consultants from Kapil minimize risks, enhance data quality, and ensure seamless integration, driving long-term success."
    },
    {
      question: "How can Kapil's Infor Consulting Services improve my business processes?",
      answer: "We provide end-to-end process optimization, customized solutions, and best practices implementation to streamline your operations and enhance efficiency."
    },
    {
      question: "What types of services do Kapil's Infor consultants typically provide?",
      answer: "Kapil's Infor consultants offer a comprehensive range of services, including migration, deployment, customization, upgrades, training, and management consulting for Infor LN, M3 and CloudSuite (Syteline), ensuring your software solutions are optimized for success."
    },
    {
      question: "What Industries can benefit from Kapil's Infor Consultation ?",
      answer: "Kapil's Infor consultation services benefit various industries, including manufacturing, distribution, fashion and apparel, healthcare, and chemicals, by optimizing operations and enhancing efficiency with tailored solutions like Infor LN, M3, and CloudSuite."
    },
    {
      question: "How do Kapil's Infor Managed Services ensure compliance and security for businesses?",
      answer: "Kapil's Infor Managed Services implement robust security protocols and compliance measures, ensuring that your systems adhere to industry regulations while protecting sensitive data and minimizing risks."
    }
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <> 
      <h3 className="text-black text-center text-2xl mb-8">FAQ's</h3>
      <div className="max-w-5xl mx-auto p-4 space-y-4">
        {faqData.map((faq, index) => (
          <div key={index} className="bg-gray-500  border-gray-200" style={{background:"#e9e7e9"}}>
            <button
              onClick={() => toggleFaq(index)}
              className="w-full px-6 py-4 text-left flex justify-between items-center hover:bg-gray-100 transition-colors duration-200"
            > 
              <h3 className="text-lg font-medium text-purple-800" style={{color:"#2e025d"}}>{faq.question}</h3>
              <span className="text-purple-600 flex-shrink-0">
                {openIndex === index ? <FaChevronUp size={20} /> : <FaChevronDown size={20} />}
              </span>
            </button>
            
            <div
              className={`px-6 transition-all duration-200 ease-in-out ${
                openIndex === index 
                  ? 'max-h-96 py-4 opacity-100' 
                  : 'max-h-0 py-0 opacity-0'
              }`}
            >
              <p className="text-gray-600">{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FAQSection;
