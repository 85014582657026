
// import React, { useState, useEffect, useRef } from 'react';

// const ClientTestimonials = () => {
//   const testimonials = [
//     {
//       logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/Finsta_Testimonials/gajapathi-logo-1.png?updatedAt=1734505337082',
//       name: 'Managing Director',
//       organization: 'Gajapathi Women cooperative society LTD',
//       feedback: 'This service has changed my life for the better! Highly recommend it.',
//     },
//     {
//       logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/Finsta_Testimonials/vedhanidhi.png?updatedAt=1734505337127',
//       name: 'Managing Director',
//       organization: 'Veda Nidhi LTD',
//       feedback: 'An exceptional experience from start to finish.',
//     },
//     {
//       logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/Finsta_Testimonials/MITHRA-MACTCS.png?updatedAt=1734505337086',
//       name: 'Managing Director',
//       organization: 'Mithra Mactcs LTD',
//       feedback: 'The team was incredibly supportive and responsive to our needs.',
//     },
//     {
//       logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/Finsta_Testimonials/rddmlslogo-150x150-1.png?updatedAt=1734505337314',
//       name: 'P.Kavitha',
//       organization: 'CEO, Rudramadevi Mahila ',
//       feedback: 'A fantastic product that exceeded our expectations!',
//     },
    
//   ];

//   const [currentIndex, setCurrentIndex] = useState(0);

//   // Auto-scroll effect
//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
//     }, 5000); // Change every 5 seconds

//     return () => clearInterval(intervalId);
//   }, []);

//   const testimonial = testimonials[currentIndex];

//   return (
//     <div className="min-h-screen flex items-center justify-center  p-4" style={{backgroundColor:"#F5F5F5"}}>
//       <div className="w-4/5 max-w-xl mx-auto">
//         <h1 className="text-2xl font-bold text-center mb-12 text-gray-800 tracking-tight" style={{color:"#003366"}}>
//           Hear From Our Satisfied Customers
//         </h1>

//         <div
//           key={currentIndex}
//           className="testimonial-card w-full bg-white rounded-2xl shadow-2xl p-8 transform transition-all duration-700 ease-in-out animate-fade-in-out"
//         >
//           <p className="text-xl text-gray-700 italic mb-6 text-center min-h-[120px]">
//             "{testimonial.feedback}"
//           </p>
//           <div className="flex items-center justify-center">
//             <img
//               src={testimonial.logo}
//               alt={testimonial.name}
//               className="w-20 h-20 rounded-full mr-6 object-cover border-4 border-indigo-100 shadow-md"
//             />
//             <div className="text-center">
//               <h2 className="text-xl font-semibold text-gray-900">{testimonial.name}</h2>
//               <p className="text-indigo-700 text-sm">{testimonial.organization}</p>
//             </div>
//           </div>
//         </div>

//         {/* Indicator dots */}
//         <div className="flex justify-center mt-8">
//           {testimonials.map((_, index) => (
//             <span
//               key={index}
//               className={`h-3 w-3 rounded-full mx-2 cursor-pointer ${currentIndex === index ? 'bg-indigo-600' : 'bg-gray-300'}`}
//                   onClick={() => setCurrentIndex(index)}
//                   style={{ backgroundColor: currentIndex === index ? 'black' : 'white' }}
//             />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ClientTestimonials;



import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const ClientTestimonials = () => {
  const testimonials = [
   {
      logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/Finsta_Testimonials/vedhanidhi.png?updatedAt=1734505337127',
      name: 'Managing Director',
      organization: 'Veda Nidhi LTD',
      feedback: `'Our Nidhi organization has benefited greatly from Finsta's assistance in becoming extremely efficient at handling all of our member portfolios centrally. We are thrilled about the platform's ability to expedite loan processing while also enabling us to personalize savings and deposit options for our members.'`,
    },
    {
      logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/Finsta_Testimonials/gajapathi-logo-1.png?updatedAt=1734505337082',
      name: 'Managing Director',
      organization: 'Gajapathi Women cooperative society LTD',
      feedback: `Finsta has tremendously helped our Cooperative society get super-efficient at managing all our member portfolios in one place. We are extremely delighted with how the platform speeds up loan processing besides empowering us to customize savings and deposit instruments for our members.`,
    },
   
    {
      logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/Finsta_Testimonials/MITHRA-MACTCS.png?updatedAt=1734505337086',
      name: 'Managing Director',
      organization: 'Mithra Mactcs LTD',
      feedback: `Our credit cooperative group now operates at a faster pace thanks to Finsta.

Thanks to our easy loan processing and member-beneficial deposit plan setup, we are now able to meet the needs of a growing number of impoverished people!`,
    },
    {
      logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/Finsta_Testimonials/rddmlslogo-150x150-1.png?updatedAt=1734505337314',
      name: 'P.Kavitha',
      organization: 'CEO, Rudramadevi Mahila',
      feedback: 'Finsta has tremendously helped our Nidhi company get super- efficient at managing all our member portfolios in one place. We are extremely delighted with how the platform speeds up loan processing besides empowering us to customize savings and deposit instruments for our members.',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const testimonial = testimonials[currentIndex];

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-indigo-50 p-4">
      <div className="w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center mb-12 text-gray-800 tracking-tight" style={{color:"#001F3F"}}>
          Our <span className="text-indigo-600">Client</span> Testimonials
        </h1>

        <div className="relative">
          <div className="absolute inset-0 flex items-center justify-between z-10">
            <button
              onClick={prevSlide}
              className="transform -translate-x-2 sm:translate-x-4 bg-white p-2 rounded-full shadow-lg hover:bg-gray-50 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <ChevronLeft className="w-6 h-6 text-gray-600" />
            </button>
            <button
              onClick={nextSlide}
              className="transform translate-x-2 sm:-translate-x-4 bg-white p-2 rounded-full shadow-lg hover:bg-gray-50 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <ChevronRight className="w-6 h-6 text-gray-600" />
            </button>
          </div>

          <div 
            key={currentIndex}
            className="testimonial-card bg-white rounded-3xl shadow-xl p-6 sm:p-8 lg:p-10 transform transition-all duration-500 hover:shadow-2xl"
          >
            <div className="flex flex-col lg:flex-row items-center lg:items-start gap-8">
              <div className="lg:w-1/3 flex flex-col items-center text-center">
                <div className="relative">
                  <div className="absolute -inset-1 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full blur opacity-25"></div>
                  <img
                    src={testimonial.logo}
                    alt={testimonial.name}
                    className="relative w-24 h-24 sm:w-32 sm:h-32 rounded-full object-cover border-4 border-white shadow-lg"
                  />
                </div>
                <h2 className="mt-4 text-xl font-semibold text-gray-900">{testimonial.name}</h2>
                <p className="text-indigo-600 font-medium text-sm sm:text-base">{testimonial.organization}</p>
              </div>
              
              <div className="lg:w-2/3 flex items-center ">
                <p className="text-lg sm:text-xl text-gray-700 italic text-justify word-wrap
                 mr-8">
                  "{testimonial.feedback}"
                </p>
              </div>
            </div>
          </div>
        </div>
    
        {/* <div className="flex justify-center mt-8 gap-3">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`h-2 rounded-full transition-all duration-300 ${
                currentIndex === index 
                  ? 
                  'w-8 bg-indigo-600' 
                  : 'w-2 bg-gray-300 hover:bg-indigo-400'
              }`}
              aria-label={`Go to testimonial ${index + 1}`}
            />
          ))}
        </div> */}

      <div className="flex justify-center mt-8 gap-3">
  {testimonials.map((_, index) => (
    <button
      key={index}
      onClick={() => setCurrentIndex(index)}
      style={{
        height: '0.5rem', 
        borderRadius: '9999px', 
        transition: 'all 0.3s', 
        width: currentIndex === index ? '2rem' : '0.5rem', 
        backgroundColor: currentIndex === index ? '#00796B' : '#D1D5DB', 
        cursor: 'pointer', 
      }}
      aria-label={`Go to testimonial ${index + 1}`}
      onMouseEnter={(e) => {
        if (currentIndex !== index) {
          e.currentTarget.style.backgroundColor = '#00796B'; 
        }
      }}
      onMouseLeave={(e) => {
        if (currentIndex !== index) {
          e.currentTarget.style.backgroundColor = '#D1D5DB'; // Reset to bg-gray-300
        }
      }}
    />
  ))}
</div>


      </div>
    </div>
  );
};

export default ClientTestimonials;