// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// const LoanPlatform = () => {
//   const [activeTab, setActiveTab] = useState('credits');
  
//   const tabData = {
//     credits: {
//       title: "Integrated Credit Assessment",
//       subtitle: "Finsta seamlessly connects with credit bureaus and organizations assessing creditworthiness, utilizing regular data updates. By leveraging advanced big data analytics, Finsta effectively evaluates the reliability of loan applicants.",
//       image: "https://ik.imagekit.io/7wqj9br0b/Lending/Big%20Data%20Technology%20for%20Business%20Finance%20Concept_.jpeg?updatedAt=1734931994609",
//       link: "Explore Cards",
//       colors: { start: '#60A5FA', mid: '#06B6D4', end: '#14B8A6' }
//     },
//     datastorage: {
//       title: "Centralized Data Storage",
//       subtitle: "Finsta centralizes customer data storage, making it accessible throughout every stage of the lending process. This streamlined approach enhances loan management efficiency and robustness.",
//       image: "https://ik.imagekit.io/7wqj9br0b/Lending/Cloud%20technology%20service%20concept%20digital%20interface%20with%20cloud%20elements%20and%20service%20function%20signs%203D%20rendering.jpeg?updatedAt=1734931994832",
//       link: "Explore Deposits",
//       colors: { start: '#34D399', mid: '#14B8A6', end: '#22C55E' }
//     },
//     automation: {
//       title: "Automation of Routine Processes",
//       subtitle: "Finsta uses robotic process automation to streamline simple, rule-based processes, accelerating loan origination and processing. This automation enhances client satisfaction while minimizing human error.",
//       image: "https://ik.imagekit.io/7wqj9br0b/Lending/Lady%20in%20suit%20holding%20pen%20symbolizing%20successful%20teamwork%20accomplishing%20newest%20project%20plans%20Woman%20carrying%20ballpoint%20pencil%20representing%20combined%20effort%20management.png?updatedAt=1734931996867",
//       link: "Explore Payments & Transfers",
//       colors: { start: '#818CF8', mid: '#A855F7', end: '#EC4899' }
//     },
//     analytics: {
//       title: "In-built Analytic Modules",
//       subtitle: "Finsta's built-in analytical reporting system generates reports that enable companies to evaluate market trends and detect patterns in customer behavior. This functionality supports the development of new products and offerings.",
//       image: "https://ik.imagekit.io/7wqj9br0b/Lending/Big%20Data%20Technology%20for%20Business%20Finance%20Concept%20uds.jpeg?updatedAt=1734931993932",
//       link: "Explore Credit & Lending",
//       colors: { start: '#FB7185', mid: '#EC4899', end: '#A855F7' }
//     },
//     integration: {
//       title: "Third-party Integration",
//       subtitle: "Finsta features robust integration capabilities with other enterprise software, enriching the lending system with valuable data and insights. The popularity of systems that connect lending modules with Fintech software for remote sales personnel is on the rise among lenders.",
//       image: "https://ik.imagekit.io/7wqj9br0b/Lending/Quality%20management%20certification%20and%20standardization%20proces%20ISO%20certified%20business.jpeg?updatedAt=1734931994368",
//       link: "Explore Data & Analytics",
//       colors: { start: '#FCD34D', mid: '#F97316', end: '#EF4444' }
//     },
//     security: {
//       title: "Comprehensive Compliance",
//       subtitle: "Finsta is an excellent lending system that possesses advanced security capabilities, ensuring the highest level of protection for customer data and networks through its Tier IV Data Centre.",
//       image: "https://ik.imagekit.io/7wqj9br0b/Lending/artificial%20intelligence%20robot%20network%20shield%20hologram.png?updatedAt=1734931996889",
//       link: "Explore Risk & Compliance",
//       colors: { start: '#4ADE80', mid: '#10B981', end: '#14B8A6' }
//     }
//   };

//   const getTabStyle = (tab) => ({
//     background: activeTab === tab
//       ? `linear-gradient(135deg, ${tabData[tab].colors.start}, ${tabData[tab].colors.mid}, ${tabData[tab].colors.end})`
//       : 'transparent',
//     color: activeTab === tab ? '#ffffff' : '#000000'
//   });

//   const getButtonGradient = (colors) => ({
//     background: `linear-gradient(135deg, ${colors.start}, ${colors.mid}, ${colors.end})`
//   });

//   return (
//     <div className="min-h-screen bg-white relative mb-8 mt-10">
//       <div className="text-center mb-8">
//         <h1 className="text-2xl md:text-3xl font-semibold mb-2 drop-shadow-lg" style={{color:"#001F3F"}}>
//           Complete loan automation with digital lending software
//         </h1>
//         <div className="w-48 h-1 mx-auto rounded-full" style={{ backgroundColor: '#008080' }}></div>
//       </div>

//       <nav className="sticky top-0 bg-white/90 backdrop-blur-lg shadow-md z-40 transition-all duration-300">
//         <div className="max-w-5xl mx-auto px-4">
//           <ul className="flex flex-wrap justify-center space-x-1 md:space-x-4 py-4">
//             {Object.keys(tabData).map((tab) => (
//               <li key={tab} className="relative">
//                 <button
//                   onClick={() => setActiveTab(tab)}
//                   className={`px-4 py-2 rounded-full text-sm md:text-base relative overflow-hidden transition-all duration-300
//                     ${activeTab === tab ? 'shadow-lg transform scale-105' : 'hover:bg-gray-100'}`}
//                   style={getTabStyle(tab)}
//                 >
//                   <span className="relative">
//                     {tab.charAt(0).toUpperCase() + tab.slice(1).replace('_', ' & ')}
//                   </span>
//                 </button>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </nav>

//       <main className="max-w-5xl mx-auto px-4 py-12">
//         <div className="flex items-center gap-12">
                  
//           <div className="max-w-xl text-center">
//             <h1 className="text-3xl md:text-4xl font-light mb-6 text-black">
//               {tabData[activeTab].title}
//             </h1>
//             <p className="text-gray-800 text-base md:text-lg mb-8">
//               {tabData[activeTab].subtitle}
//             </p>
//             <div className="flex flex-wrap  gap-4">
//               <button
//                 className="px-6 py-3  rounded-full text-white shadow-md hover:shadow-lg transform hover:scale-105 transition-all duration-300"
//                 style={getButtonGradient(tabData[activeTab].colors)}
//               >
//                 <Link to="/contact" >Contact Sales →</Link>
//               </button>
              
//             </div>
//           </div>

//           <div className="w-full max-w-3xl">
//             <div className="relative">
//               <img
//                 src={tabData[activeTab].image}
//                 alt="Banking platform visualization"
//                 className=" object-contain rounded-lg shadow-xl transform transition-all duration-500 hover:scale-[1.02]"
//               />
//             </div>
//          </div>
                  
//         </div>
//       </main>
//     </div>
//   );
// };

// export default LoanPlatform;


import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const LoanPlatform = () => {
  const [activeTab, setActiveTab] = useState('credits');
  
  const tabData = {
    credits: {
      title: "Integrated Credit Assessment",
      subtitle: "Finsta seamlessly connects with credit bureaus and organizations assessing creditworthiness, utilizing regular data updates. By leveraging advanced big data analytics, Finsta effectively evaluates the reliability of loan applicants.",
      image: "https://ik.imagekit.io/7wqj9br0b/Lending/Big%20Data%20Technology%20for%20Business%20Finance%20Concept_.jpeg?updatedAt=1734931994609",
      link: "Explore Cards",
      colors: { start: '#60A5FA', mid: '#06B6D4', end: '#14B8A6' }
    },
    datastorage: {
      title: "Centralized Data Storage",
      subtitle: "Finsta centralizes customer data storage, making it accessible throughout every stage of the lending process. This streamlined approach enhances loan management efficiency and robustness.",
      image: "https://ik.imagekit.io/7wqj9br0b/Lending/Cloud%20technology%20service%20concept%20digital%20interface%20with%20cloud%20elements%20and%20service%20function%20signs%203D%20rendering.jpeg?updatedAt=1734931994832",
      link: "Explore Deposits",
      colors: { start: '#34D399', mid: '#14B8A6', end: '#22C55E' }
    },
    automation: {
      title: "Automation of Routine Processes",
      subtitle: "Finsta uses robotic process automation to streamline simple, rule-based processes, accelerating loan origination and processing. This automation enhances client satisfaction while minimizing human error.",
      image: "https://ik.imagekit.io/7wqj9br0b/Lending/Lady%20in%20suit%20holding%20pen%20symbolizing%20successful%20teamwork%20accomplishing%20newest%20project%20plans%20Woman%20carrying%20ballpoint%20pencil%20representing%20combined%20effort%20management.png?updatedAt=1734931996867",
      link: "Explore Payments & Transfers",
      colors: { start: '#818CF8', mid: '#A855F7', end: '#EC4899' }
    },
    analytics: {
      title: "In-built Analytic Modules",
      subtitle: "Finsta's built-in analytical reporting system generates reports that enable companies to evaluate market trends and detect patterns in customer behavior. This functionality supports the development of new products and offerings.",
      image: "https://ik.imagekit.io/7wqj9br0b/Lending/Big%20Data%20Technology%20for%20Business%20Finance%20Concept%20uds.jpeg?updatedAt=1734931993932",
      link: "Explore Credit & Lending",
      colors: { start: '#FB7185', mid: '#EC4899', end: '#A855F7' }
    },
    integration: {
      title: "Third-party Integration",
      subtitle: "Finsta features robust integration capabilities with other enterprise software, enriching the lending system with valuable data and insights. The popularity of systems that connect lending modules with Fintech software for remote sales personnel is on the rise among lenders.",
      image: "https://ik.imagekit.io/7wqj9br0b/Lending/Quality%20management%20certification%20and%20standardization%20proces%20ISO%20certified%20business.jpeg?updatedAt=1734931994368",
      link: "Explore Data & Analytics",
      colors: { start: '#FCD34D', mid: '#F97316', end: '#EF4444' }
    },
    security: {
      title: "Comprehensive Compliance",
      subtitle: "Finsta is an excellent lending system that possesses advanced security capabilities, ensuring the highest level of protection for customer data and networks through its Tier IV Data Centre.",
      image: "https://ik.imagekit.io/7wqj9br0b/Lending/artificial%20intelligence%20robot%20network%20shield%20hologram.png?updatedAt=1734931996889",
      link: "Explore Risk & Compliance",
      colors: { start: '#4ADE80', mid: '#10B981', end: '#14B8A6' }
    }
  };

  const getTabStyle = (tab) => ({
    background: activeTab === tab 
      ? `linear-gradient(135deg, ${tabData[tab].colors.start}, ${tabData[tab].colors.mid}, ${tabData[tab].colors.end})`
      : 'transparent',
    color: activeTab === tab ? '#ffffff' : '#000000'
  });

  const getButtonGradient = (colors) => ({
    background: `linear-gradient(135deg, ${colors.start}, ${colors.mid}, ${colors.end})`
  });

  return (
    <div className="min-h-screen bg-white relative py-6 md:py-8">
      {/* Header Section */}
      <div className="text-center mb-6 md:mb-8 px-4">
        <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold mb-3 md:mb-4 drop-shadow-lg text-[#001F3F] leading-tight">
          Complete loan automation with digital lending software
        </h1>
        <div className="w-20 sm:w-32 md:w-48 h-1 mx-auto rounded-full bg-[#008080]"></div>
      </div>

      {/* Navigation Tabs */}
      <nav className="sticky top-0 bg-white/90 backdrop-blur-lg shadow-md z-40 transition-all duration-300">
        <div className="max-w-7xl mx-auto scrollbar-hide">
          <div className="flex flex-wrap justify-center gap-2 py-3 px-4 md:px-6">
            {Object.keys(tabData).map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`px-3 py-1.5 md:px-4 md:py-2 rounded-full text-xs sm:text-sm md:text-base 
                  transition-all duration-300 hover:shadow-lg whitespace-nowrap
                  ${activeTab === tab ? 'shadow-lg transform scale-105' : 'hover:bg-gray-100'}`}
                style={getTabStyle(tab)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1).replace('_', ' & ')}
              </button>
            ))}
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 md:px-6 py-6 md:py-8">
        <div className="flex flex-col lg:flex-row items-center gap-6 md:gap-8 lg:gap-12">
          {/* Text Content */}
          <div className="w-full lg:w-1/2 text-center lg:text-left space-y-4 md:space-y-6">
            <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light text-black leading-tight">
              {tabData[activeTab].title}
            </h2>
            <p className="text-gray-800 text-sm sm:text-base md:text-lg max-w-2xl mx-auto lg:mx-0 leading-relaxed">
              {tabData[activeTab].subtitle}
            </p>
            <div className="flex justify-center lg:justify-start pt-2">
              <Link 
                to="/contact"
                className="inline-block px-4 sm:px-6 py-2 sm:py-3 rounded-full text-white shadow-md 
                  hover:shadow-lg transform hover:scale-105 transition-all duration-300 
                  text-sm sm:text-base"
                style={getButtonGradient(tabData[activeTab].colors)}
              >
                Contact Sales →
              </Link>
            </div>
          </div>

          {/* Image */}
          <div className="w-full lg:w-1/2">
            <div className="relative  overflow-hidden">
              <img
                src={tabData[activeTab].image}
                alt="Banking platform visualization"
                className=" w-96 h-96 object-contain transform transition-all duration-500 hover:scale-105"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LoanPlatform;

// Add this CSS to your global styles or component
const styles = `
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
`;