// import React from 'react';
// import { FaWhatsapp } from 'react-icons/fa';
// import Whasappicon from '../../../../Assets/InforPage/whatsappIcon.png'

// const WhatsAppButton = () => {
//   const handleWhatsAppClick = () => {
//     const whatsappNumber = '9154673864';
//     const whatsappUrl = `https://wa.me/${whatsappNumber}`;
//     window.open(whatsappUrl, '_blank');
//   };

//   return (
//     <button
//       onClick={handleWhatsAppClick}
//       className="fixed left-6 bottom-16 bg-green-500 hover:bg-green-600 text-white p-4 rounded-full shadow-lg transition-all duration-300 hover:scale-110 z-50"
//       aria-label="Open WhatsApp"
//       >
//           <img src={Whasappicon} alt="whatsapp" className="w-10 h-10" />
//       {/* <FaWhatsapp className="w-8 h-8" /> */}
//     </button>
//   );
// };

// export default WhatsAppButton;


import React, { useState } from 'react';
import { MessageCircle, X } from 'lucide-react';
import Whasappicon from '../../../../Assets/InforPage/whatsappIcon.png'
const WhatsAppButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  
  const whatsappNumber = '9154673864';
  const defaultMessage = "Hello! I need a Demo on Infor Services.";
  
  const handleWhatsAppClick = () => {
    const encodedMessage = encodeURIComponent(message || defaultMessage);
    
    // Check if device is mobile
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    // Use web.whatsapp.com for desktop and wa.me for mobile
    const whatsappUrl = isMobile
      ? `https://wa.me/${whatsappNumber}?text=${encodedMessage}`
      : `https://web.whatsapp.com/send/?phone=${whatsappNumber}&text=${encodedMessage}`;
      
    // Open in new tab
    window.open(whatsappUrl, '_blank');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleWhatsAppClick();
    setIsOpen(false);
    setMessage('');
  };

  return (
    <div className="fixed left-6 bottom-16 z-50">
      {/* Main Button */}
      <button
        style={{background:"#228B22"}}
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-green-500 hover:bg-green-600 text-white p-4 rounded-full shadow-lg transition-all duration-300 hover:scale-110 ${isOpen ? 'rotate-180' : ''}`}
        aria-label={isOpen ? 'Close WhatsApp form' : 'Open WhatsApp form'}
      >
        {isOpen ? (
          <X className="w-8 h-8" />
        ) : (
            // <MessageCircle className="w-8 h-8" />
            <img src={Whasappicon} alt="whatsapp"  className="w-8 h-8"/>
        )}
      </button>

      {/* Tooltip */}
      <div className={`absolute left-20 w-28 bottom-2 bg-white text-sm text-gray-600 py-1 px-3 rounded-lg shadow-md transition-opacity duration-300 ${isOpen ? 'opacity-0' : 'opacity-100'}`}>
        Chat with us on WhatsApp
      </div>

      {/* Popup Form */}
      <div className={`absolute bottom-20 left-0 transition-all duration-300 ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'}`}>
        <div className="bg-white rounded-lg shadow-xl p-6 w-72">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">
            Send us a message
          </h3>
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={defaultMessage}
                className="w-full h-24 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-green-500 resize-none"
              />
            </div>
            
            <div className="text-sm text-gray-500 mb-4">
              {navigator.userAgent.match(/iPhone|iPad|iPod|Android/i)
                ? "Opens in WhatsApp app"
                : "Opens in WhatsApp Web"}
            </div>
            
            <button
              style={{color:"white",background:"#008000"}}
              type="submit"
              className="w-full border border-green bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-300 flex items-center justify-center space-x-2"
            >
              <MessageCircle className="w-5 h-5" />
               {/* <img src={Whasappicon} alt="whatsapp"  className="w-8 h-8"/> */}
              <span>Start Chat</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppButton;



// import React, { useState } from 'react';
// import { MessageCircle, X } from 'lucide-react';
// import Whasappicon from '../../../../Assets/InforPage/whatsappIcon.png'


// const WhatsAppButton = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [message, setMessage] = useState('');
  
//   const styles = {
//     container: {
//       position: 'fixed',
//       left: '24px',
//       bottom: '64px',
//       zIndex: 50
//     },
//     mainButton: {
//       backgroundColor: '#25D366', // WhatsApp green
//       color: 'white',
//       padding: '16px',
//       borderRadius: '50%',
//       border: 'none',
//       boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//       transition: 'all 0.3s ease',
//       cursor: 'pointer',
//       transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
//     },
//     tooltip: {
//       position: 'absolute',
//       left: '80px',
//       bottom: '8px',
//       backgroundColor: 'white',
//       padding: '4px 12px',
//       borderRadius: '8px',
//       boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//       fontSize: '14px',
//       color: '#4B5563',
//       opacity: isOpen ? 0 : 1,
//       transition: 'opacity 0.3s ease'
//     },
//     popup: {
//       position: 'absolute',
//       bottom: '80px',
//       left: '0',
//       opacity: isOpen ? 1 : 0,
//       transform: isOpen ? 'scale(1)' : 'scale(0.95)',
//       pointerEvents: isOpen ? 'auto' : 'none',
//       transition: 'all 0.3s ease'
//     },
//     popupContent: {
//       backgroundColor: 'white',
//       borderRadius: '12px',
//       boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//       padding: '24px',
//       width: '288px'
//     },
//     heading: {
//       fontSize: '18px',
//       fontWeight: 600,
//       color: '#1F2937',
//       marginBottom: '16px'
//     },
//     textarea: {
//       width: '100%',
//       height: '96px',
//       padding: '8px 12px',
//       borderRadius: '8px',
//       border: '1px solid #E5E7EB',
//       resize: 'none',
//       fontSize: '14px',
//       color: '#4B5563',
//       outline: 'none'
//     },
//     deviceInfo: {
//       fontSize: '14px',
//       color: '#6B7280',
//       marginBottom: '16px',
//       marginTop: '8px'
//     },
//     submitButton: {
//       width: '100%',
//       backgroundColor: '#25D366',
//       color: 'white',
//       padding: '8px 16px',
//       borderRadius: '8px',
//       border: 'none',
//       fontWeight: 500,
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       gap: '8px',
//       cursor: 'pointer',
//       transition: 'background-color 0.3s ease'
//     }
//   };

//   const handleButtonHover = (e) => {
//     e.currentTarget.style.backgroundColor = '#128C7E'; // Darker WhatsApp green
//     e.currentTarget.style.transform = 'scale(1.1)';
//   };

//   const handleButtonLeave = (e) => {
//     e.currentTarget.style.backgroundColor = '#25D366';
//     e.currentTarget.style.transform = isOpen ? 'rotate(180deg)' : 'rotate(0)';
//   };

//   const whatsappNumber = '9154673864';
//   const defaultMessage = "Hello! I'm interested in learning more.";
  
//   const handleWhatsAppClick = () => {
//     const encodedMessage = encodeURIComponent(message || defaultMessage);
//     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
//     const whatsappUrl = isMobile
//       ? `https://wa.me/${whatsappNumber}?text=${encodedMessage}`
//       : `https://web.whatsapp.com/send/?phone=${whatsappNumber}&text=${encodedMessage}`;
//     window.open(whatsappUrl, '_blank');
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     handleWhatsAppClick();
//     setIsOpen(false);
//     setMessage('');
//   };

//   return (
//     <div style={styles.container}>
//       <button
//         onClick={() => setIsOpen(!isOpen)}
//         onMouseEnter={handleButtonHover}
//         onMouseLeave={handleButtonLeave}
//         style={styles.mainButton}
//         aria-label={isOpen ? 'Close WhatsApp form' : 'Open WhatsApp form'}
//       >
//         {isOpen ? (
//           <X style={{ width: '32px', height: '32px' }} />
//         ) : (
//             // <MessageCircle style={{ width: '32px', height: '32px' }} />
//             <img src={Whasappicon} alt="WhatsApp" style={{ width: '32px', height: '32px' }} />
//         )}
//       </button>

//       <div style={styles.tooltip} className='w-32'>
//         Chat with us on WhatsApp
//       </div>

//       <div style={styles.popup}>
//         <div style={styles.popupContent}>
//           <h3 style={styles.heading}>
//             Send us a message
//           </h3>
          
//           <form onSubmit={handleSubmit}>
//             <textarea
//               value={message}
//               onChange={(e) => setMessage(e.target.value)}
//               placeholder={defaultMessage}
//               style={styles.textarea}
//             />
            
//             <div style={styles.deviceInfo}>
//               {navigator.userAgent.match(/iPhone|iPad|iPod|Android/i) 
//                 ? "Opens in WhatsApp app" 
//                 : "Opens in WhatsApp Web"}
//             </div>
            
//             <button
//               type="submit"
//               style={styles.submitButton}
//               onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#128C7E'}
//               onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#25D366'}
//             >
//               <MessageCircle style={{ width: '20px', height: '20px' }} />
//               <span>Start Chat</span>
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WhatsAppButton;