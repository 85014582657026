import React from 'react';
import { FaCube, FaShieldAlt, FaNetworkWired, FaFileAlt, FaBuilding, FaGraduationCap, FaExclamationCircle, FaCogs } from 'react-icons/fa';

const KeyBenefits = () => {
  const benefits = [
    { 
      title: "Integration of all products and services", 
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/Corebanking_icons/15.png?updatedAt=1734584059165"
    },
    { 
      title: "Robust risk management system with easy reconciliation of financial data", 
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/Corebanking_icons/17.png?updatedAt=1734584059684"
    },
    { 
      title: "Support of multiple communication channels and payment gateways", 
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/Corebanking_icons/16.png?updatedAt=1734584059551"
    },
    { 
      title: "Automated report generation across multiple formats", 
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/Corebanking_icons/19.png?updatedAt=1734584058398" 
    },
    { 
      title: "Ability for financial institutions to issue their financial positions at the end of the day in any branch", 
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/Corebanking_icons/20.png?updatedAt=1734584059468" 
    },
    { 
      title: "Low learning curve for bank users", 
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/Corebanking_icons/21.png?updatedAt=1734584059151"
    },
    { 
      title: "Easy interpretable errors", 
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/Corebanking_icons/22.png?updatedAt=1734584058846"
    },
    { 
      title: "Independent running of system processes", 
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/Corebanking_icons/23.png?updatedAt=1734584059529"
    },
  ];

  return (
    <div className="bg-gradient-to-br from-blue-50 to-blue-100 py-16 font-inter">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-[#090979] mb-4">
            Key Benefits
          </h2>

          <p className="text-xl text-gray-600 max-w-2xl mx-auto text-center">
            Technology that drives to the winning transformation
          </p>

          <div className="w-24 h-1 bg-[#141485] rounded-full mx-auto mt-4"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <div 
              key={index}
              className="bg-white rounded-2xl p-6 text-center shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-105 group"
            >
              <div className="flex justify-center items-center mb-6">
               <img src={benefit.icon} alt="icon" className='h-24' /> 
              </div>
              <p className="text-md text-justify text-gray-400 group-hover:text-blue-800 transition-colors duration-300">
                {benefit.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KeyBenefits;