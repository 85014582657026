
import React from 'react';

const Features = () => {
  const features = [
    {
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/coreBanking_Favicons/growth.png?updatedAt=1734947351530",
      title: "Deposit Growth",
      description: "Finsta's bank customers experience significantly higher growth in their deposit portfolios, exceeding their like-for-like peers by 32%.",
      colors: {
        gradient: 'linear-gradient(135deg, #4ecdc4, #45b7d1)',
        shadow: 'rgba(78, 205, 196, 0.3)'
      }
    },
    {
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/coreBanking_Favicons/app-development.png?updatedAt=1734947351405",
      title: "Product Tailoring",
      description: "Finsta customers can reduce costs by as much as 45% when it comes to product customizations and modifications.",
      colors: {
        gradient: 'linear-gradient(135deg, #ff6b6b, #feca57)',
        shadow: 'rgba(255, 107, 107, 0.3)'
      }
    },
    {
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/coreBanking_Favicons/data-analysis.png?updatedAt=1734947351288",
      title: "Cost Benefits",
      description: "Finsta's cloud platform is 35-50% cheaper than legacy providers, enhancing its value proposition.",
      colors: {
        gradient: 'linear-gradient(135deg, #5f27cd, #48dbfb)',
        shadow: 'rgba(95, 39, 205, 0.3)'
      }
    },
    {
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/coreBanking_Favicons/web-programming.png?updatedAt=1734948411962",
      title: "Low Code UI",
      description: "Banks do not require coding specialists to utilize Finsta, as its user-friendly low-code environment simplifies the process.",
      colors: {
        gradient: 'linear-gradient(135deg, #ff9ff3, #54a0ff)',
        shadow: 'rgba(255, 159, 243, 0.3)'
      }
    }
  ];

  return (
    <div className="bg-gradient-to-br from-gray-100 to-blue-50 py-12 md:py-16 font-sans">
      <div className="max-w-4xl mx-auto text-center mb-8 md:mb-12 px-4">
        <h2 className="text-sm md:text-base font-semibold uppercase text-gray-800 tracking-wider mb-2">
          What to Expect
        </h2>
        <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-blue-900 mb-4">
          Outperform with Finsta's Banking Platform
        </h1>
        <div className="w-16 md:w-24 h-1 bg-blue-900 mx-auto rounded-full"></div>
      </div>

      <div className="max-w-screen-xl mx-auto px-4 md:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="rounded-lg p-6 md:p-8 text-center transition-all duration-300 ease-in-out"
              style={{
                background: feature.colors.gradient,
                boxShadow: `0 15px 25px ${feature.colors.shadow}`,
                cursor: 'pointer'
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'translateY(-8px)';
                e.currentTarget.style.boxShadow = `0 25px 35px ${feature.colors.shadow}`;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'translateY(0)';
                e.currentTarget.style.boxShadow = `0 15px 25px ${feature.colors.shadow}`;
              }}
            >
              <div className="flex justify-center items-center mb-6">
                <img 
                  src={feature.icon} 
                  alt={feature.title}
                  className="w-16 h-16 md:w-20 md:h-20 object-contain"
                  // style={{ filter: 'brightness(0) invert(1) opacity(0.9)' }}
                />
              </div>
              <h3 
                className="text-lg md:text-xl font-bold text-white mb-3 md:mb-4"
                style={{ textShadow: '0 2px 4px rgba(0,0,0,0.2)' }}
              >
                {feature.title}
              </h3>
              <p 
                className="text-white opacity-90 text-sm md:text-base leading-relaxed"
              >
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;