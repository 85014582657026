// import React, { useState, useEffect, useCallback } from 'react';
// import FinstaLogo from '../../../../../../Assets/Finsta/finsta_Logo.png';
// import { Link } from 'react-router-dom';

// const IntroLending = () => {
//   const slides = [
//     {
//       title: "Comprehensive Loan Tracking",
//       description: "Empowering you to monitor and manage loans effectively with real-time insights and analytics.",
//       imageSrc: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/14.png?updatedAt=1734519963990"
//     },
//     {
//       title: "Smart Portfolio Optimization",
//       description: "Tailored strategies to elevate your business to new heights.",
//       imageSrc: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/2.png?updatedAt=1734519963386"
//     },
//     {
//       title: "Automated Risk Assessment",
//       description: "Transforming data into actionable insights to identify and mitigate risks in your loan portfolio.",
//       imageSrc: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/3.png?updatedAt=1734519963167"
//     },
//      {
//       title: "Seamless Compliance Management",
//       description: "Ensuring regulatory compliance effortlessly with built-in tools and automated reporting features.",
//       imageSrc: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/13.png?updatedAt=1734519963167"
//     }
//   ];

//   const [currentSlide, setCurrentSlide] = useState(0);
//   const [isAutoPlaying, setIsAutoPlaying] = useState(true);

//   // Auto-play functionality
//   useEffect(() => {
//     let intervalId;
//     if (isAutoPlaying) {
//       intervalId = setInterval(() => {
//         setCurrentSlide((prev) => (prev + 1) % slides.length);
//       }, 5000); // Change slide every 5 seconds
//     }
//     return () => clearInterval(intervalId);
//   }, [isAutoPlaying, slides.length]);

//   // Navigation handlers
//   const goToSlide = useCallback((index) => {
//     setCurrentSlide(index);
//     // Pause auto-play when user interacts
//     setIsAutoPlaying(false);
    
//     // Restart auto-play after 10 seconds of inactivity
//     const timeoutId = setTimeout(() => {
//       setIsAutoPlaying(true);
//     }, 10000);

//     return () => clearTimeout(timeoutId);
//   }, []);

//   return (
//     <div>
//       <div
//         className="relative min-h-screen text-white overflow-hidden"
//         style={{
//           background: '#E6E6FA',
//         }}
//       >
//         {/* Navigation Bar */}
//         <nav className="flex items-center justify-between py-5 px-10 bg-white bg-opacity-80 z-50">
//           {/* Logo */}
//           <div className="flex items-center">
//             <img src={FinstaLogo} alt="Finsta Logo" className="h-8" />
//           </div>

//           {/* Navigation Links */}
//           <div className="flex items-center space-x-6 md:space-x-8">
//             <Link
//               to="/services/Finsta/Corebanking"
//               className="text-black hover:text-blue-600 transition duration-200 text-sm sm:text-base"
              
//             >
//               Core Banking
//             </Link>
//             <Link
//               to="/services/Finsta/Lending"
//               className="text-black hover:text-blue-600 transition duration-200 text-sm sm:text-base"
//             >
//               Lending
//             </Link>
//           </div>
//         </nav>

//         {/* Background Decorative Gradient */}
//         <div
//           className="absolute inset-0 z-10 pointer-events-none"
//           style={{
//             background: 'radial-gradient(circle at 75% 25%, rgba(255,255,255,0.1) 0%, transparent 50%)',
//             opacity: 0.5
//           }}
//         />

//         {/* Carousel */}
//         <div className="relative w-full max-w-6xl mx-auto overflow-hidden">
//           {/* Slide Container */}
//           <div
//             className="flex transition-transform duration-700 ease-in-out"
//             style={{
//               transform: `translateX(-${currentSlide * 100}%)`,
//               height: '80vh',
//               maxHeight: '800px'
//             }}
//           >
//             {slides.map((slide, index) => (
//               <div
//                 key={index}
//                 className="flex-none w-full flex items-center justify-center p-8"
//                 style={{ minWidth: '100%' }}
//               >
//                 {/* Left Content: Text */}
//                 <div className="w-3/5 space-y-6">
//                   <h2 className="text-5xl font-bold leading-tight text-white drop-shadow-lg" style={{color:"#001F3F"}}>
//                     {slide.title}
//                   </h2>
//                   <p className="text-xl text-white/90 max-w-xl" style={{color:"#00796B"}}>{slide.description}</p>
//                   <div className="flex items-center space-x-4"  >
//                     <Link
//                       to="/contact"
//                       style={{background:"#008080"}}
//                       className="bg-white text-black px-6 py-3 rounded-full font-semibold flex items-center space-x-2 hover:bg-cyan-100 transition duration-300 transform hover:scale-105 shadow-lg"
//                     >
//                       <span>Talk to our team</span>
//                     </Link>
//                   </div>
//                 </div>

//                 {/* Right Content: Image */}
//                 <div className="w-1/2 flex justify-center">
//                   <img
//                     src={slide.imageSrc}
//                     alt="Slide Illustration"
//                     className="w-96 object-contain"
//                   />
//                 </div>
//               </div>
//             ))}
//           </div>

//           {/* Navigation Dots */}
//           <div className="absolute bottom-8 left-0 right-0 flex justify-center space-x-3">
//             {slides.map((_, index) => (
//               <button
//                 key={index}
//                 onClick={() => goToSlide(index)}
//                 className={`
//                   w-2 h-2 rounded-full transition-all duration-300 ease-in-out
//                   ${index === currentSlide
//                     ? 'bg-white scale-125 shadow-lg'
//                     : 'bg-black hover:bg-gray-300'}
//                 `}
//                 aria-label={`Go to slide ${index + 1}`}
//               />
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default IntroLending;


import React, { useState, useEffect, useCallback } from 'react';
import FinstaLogo from '../../../../../../Assets/Finsta/finsta_Logo.png';
import { Link } from 'react-router-dom';
import FinstaNav from '../FinstaNav';
const IntroLending = () => {
  const slides = [
    {
      title: "Comprehensive Loan Tracking",
      description: "Empowering you to monitor and manage loans effectively with real-time insights and analytics.",
      imageSrc: "https://ik.imagekit.io/7wqj9br0b/Lending/LendingIntro/3%20-%20Copy.png?updatedAt=1735189718645"
    },
    {
      title: "Smart Portfolio Optimization",
      description: "Tailored strategies to elevate your business to new heights.",
      imageSrc: "https://ik.imagekit.io/7wqj9br0b/Intro/12%20-%20Copy.png?updatedAt=1735192049864"
    },
    {
      title: "Automated Risk Assessment",
      description: "Transforming data into actionable insights to identify and mitigate risks in your loan portfolio.",
      imageSrc: "https://ik.imagekit.io/7wqj9br0b/Intro/13%20-%20Copy.png?updatedAt=1735192049440"
    },
    {
      title: "Seamless Compliance Management",
      description: "Ensuring regulatory compliance effortlessly with built-in tools and automated reporting features.",
      imageSrc: "https://ik.imagekit.io/7wqj9br0b/Lending/LendingIntro/4%20-%20Copy.png?updatedAt=1735189719046"
    }
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  useEffect(() => {
    let intervalId;
    if (isAutoPlaying) {
      intervalId = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
      }, 5000);
    }
    return () => clearInterval(intervalId);
  }, [isAutoPlaying, slides.length]);

  const goToSlide = useCallback((index) => {
    setCurrentSlide(index);
    setIsAutoPlaying(false);
    
    const timeoutId = setTimeout(() => {
      setIsAutoPlaying(true);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div>
      <div 
        className="relative min-h-screen text-white overflow-hidden"
        style={{
          background: '#E6E6FA',
        }}
      >

        <FinstaNav/>
        {/* Navigation Bar */}
        {/* <nav className="flex flex-col sm:flex-row items-center justify-between py-3 sm:py-5 px-4 sm:px-10 bg-white bg-opacity-80 z-50">
         
        

         
          <div className="flex items-center space-x-4 sm:space-x-6 md:space-x-8">
            <Link
              to="/services/Finsta/Corebanking"
                className='border-2 border-[#E6E6FA] px-2 py-3 flex justify-center items-center rounded-full space-x-2 transition duration-300 transform hover:scale-110 hover:bg-[#E6E6FA]  shadow-lg text-center m-auto w-full max-w-xs h-12 bg-black text-white tracking-wide sm:w-36 md:w-36 lg:w-36 xl:w-36'
                style={{ transition: 'color 0.5s ease' }}
              onMouseEnter={(e) => e.currentTarget.style.color = 'black'}
              onMouseLeave={(e) => e.currentTarget.style.color = 'white'}
            >
              Core Banking
            </Link>
          

            <Link
              to="/services/Finsta/Lending"
              className='border-2 border-[#E6E6FA] px-2 py-3 flex justify-center items-center rounded-full space-x-2 transition duration-300 transform hover:scale-110 hover:bg-[#E6E6FA]  shadow-lg text-center m-auto w-full max-w-xs h-12 bg-black text-white tracking-wide sm:w-24 md:w-36 lg:w-36 xl:w-36'
                style={{ transition: 'color 0.5s ease' }}
              onMouseEnter={(e) => e.currentTarget.style.color = 'black'}
              onMouseLeave={(e) => e.currentTarget.style.color = 'white'}
            >
              Lending
            </Link>

             
          </div>

            <div className="flex items-center mb-3 sm:mb-0">
            <Link  to="/services/Finsta" ><img src={FinstaLogo} alt="Finsta Logo" className="h-6 sm:h-8" /></Link>
            
          </div>
        </nav> */}

        {/* Background Decorative Gradient */}
        <div
          className="absolute inset-0 z-10 pointer-events-none"
          style={{
            background: 'radial-gradient(circle at 75% 25%, rgba(255,255,255,0.1) 0%, transparent 50%)',
            opacity: 0.5
          }}
        />

        {/* Carousel */}
        <div className="relative w-full max-w-7xl mx-auto overflow-hidden px-4 sm:px-6">
          {/* Slide Container */}
          <div
            className="flex transition-transform duration-700 ease-in-out"
            style={{ 
              transform: `translateX(-${currentSlide * 100}%)`,
              height: 'calc(100vh - 80px)',
              maxHeight: '800px'
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                className="flex-none w-full flex flex-col md:flex-row items-center justify-center p-4 sm:p-8 gap-6 md:gap-0"
                style={{ minWidth: '100%' }}
              >
                {/* Left Content: Text */}
                <div className="w-full md:w-3/5 space-y-4 sm:space-y-6 text-center md:text-left order-2 md:order-1">
                  <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold leading-tight drop-shadow-lg" 
                      style={{color:"#001F3F"}}>
                    {slide.title}
                  </h2>
                  <p className="text-base sm:text-lg md:text-xl max-w-xl mx-auto md:mx-0" 
                     style={{color:"#00796B"}}>
                    {slide.description}
                  </p>
                  <div className="flex items-center justify-center md:justify-start space-x-4">
                    <Link 
                      to="/contact"
                      style={{background:"#008080"}}
                      className=" text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full font-semibold flex items-center space-x-2 hover:bg-cyan-100 transition duration-300 transform hover:scale-105 shadow-lg text-sm sm:text-base"
                    >
                      <span>Talk to our team</span>
                    </Link>
                  </div>
                </div>

                {/* Right Content: Image */}
                <div className="w-full md:w-1/2 flex justify-center order-1 md:order-2">
                  <img
                    src={slide.imageSrc}
                    alt="Slide Illustration"
                    className="w-64 sm:w-80 md:w-96 object-contain"
                  />
                </div>
              </div>
            ))}
          </div>

          {/* Navigation Dots */}
          <div className="absolute bottom-4 sm:bottom-8 left-0 right-0 flex justify-center space-x-2 sm:space-x-3">
            {slides.map((_, index) => (
              <button
                key={index}
                onClick={() => goToSlide(index)}
                className={`
                  w-2 h-2 rounded-full transition-all duration-300 ease-in-out
                  ${index === currentSlide 
                    ? 'bg-white scale-125 shadow-lg' 
                    : 'bg-black hover:bg-gray-300'}
                `}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroLending;