import React from 'react';

const PartnerCard = ({ title, description, icon }) => (
  <div className="bg-white rounded-lg p-6 shadow-lg transition-transform duration-300 transform hover:scale-105">
    <div className="flex flex-col items-center text-center space-y-4">
      <img src={icon} alt={title} className='h-32 w-32 object-contain mb-4' />
      <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
      <p className="text-gray-600 text-md text-justify leading-relaxed max-w-xs">
        {description}
      </p>
    </div>
  </div>
);

const Cards = () => {
  const partners = [
    {
      title: "Onboarding",
      description: "Experience seamless customer onboarding with our software, featuring the most compatible interface and adaptable features designed to enhance user engagement and retention.",
      icon: "https://ik.imagekit.io/7wqj9br0b/Finsta_icons/5.png?updatedAt=1734506016516"
    },
    {
      title: "Secure Payments",
      description: "Effortlessly verify accounts, check balances, and facilitate multi-rail payments with our secure payment gateway APIs, meticulously designed for faster and more reliable money movement.",
      icon: "https://ik.imagekit.io/7wqj9br0b/Finsta_icons/2.png?updatedAt=1734506016421"
    },
    {
      title: "Fraud Prevention",
      description: "Enhance fraud prevention with real-time alerts, advanced identity verification, and network-driven signals for comprehensive security.",
      icon: "https://ik.imagekit.io/7wqj9br0b/Finsta_icons/6.png?updatedAt=1734506017347"
    },
    {
      title: "Credit Understanding",
      description: "Make better lending decisions with easy access to cash flow data and risk insights from the Finsta Network.",
      icon: "https://ik.imagekit.io/7wqj9br0b/Finsta_icons/3.png?updatedAt=1734506017354"
    }
  ];

  return (
    <div className="container mx-auto px-4 py-12" style={{ backgroundColor: '#f9f9f9' }}>
      <div className='text-center mb-12'>
        <h4 className='font-semibold text-gray-700'>Effective Solutions That Collaborate Perfectly</h4>
        <h2 className='font-bold  text-4xl mt-4'  style={{color:"#003366"}} >Quick. Secure. Effortless</h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {partners.map((partner, index) => (
          <PartnerCard
            key={index}
            title={partner.title}
            description={partner.description}
            icon={partner.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default Cards;