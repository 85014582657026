import React from 'react';

const cards = [
  {
    title: "CONSULT",
    description: "Kapil's Consulting Team offers expert ERP implementation and business advisory services."
  },
  {
    title: "ADVISE",
    description: "We guide our clients towards innovative technologies through strategy, direction, and solutioning."
  },
  {
    title: "INTEGRATE",
    description: "Enterprise Application Integration is how Kapil bridges gaps between different software applications."
  }
];

const styles = {
  container: {
    backgroundColor: 'white'
  },
  frontBg: {
    backgroundColor: '	#ff6700'
  },
  backBg: {
    backgroundColor: '#ff0000'
  },
  frontText: {
    color: '#ffffff'
  },
  backText: {
    color: 'black'
  }
};

const FlipCards = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full max-w-5xl mx-auto p-4" style={styles.container}>
      {cards.map((card, index) => (
        <div key={index} className="group [perspective:1000px]">
          <div className="relative transition-all duration-700 ease-in-out [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] h-40">
            {/* Front of card */}
            <div className="absolute inset-0 rounded-xl overflow-hidden [backface-visibility:hidden]">
              <div 
                className="absolute inset-0 transition-all duration-700 ease-in-out 
                          group-hover:translate-y-full group-hover:scale-125"
                style={styles.frontBg}
              >
              </div>
              <div className="relative h-full flex items-center justify-center p-6">
                <h2 
                  className="text-xl sm:text-2xl font-bold text-center 
                           transition-all duration-700 ease-in-out transform"
                  style={styles.frontText}
                >
                  {card.title}
                </h2>
              </div>
            </div>
            
            {/* Back of card */}
            <div className="absolute inset-0 rounded-xl overflow-hidden [backface-visibility:hidden] [transform:rotateY(180deg)]">
              <div 
                className="absolute inset-0 transition-all duration-700 ease-in-out 
                          group-hover:translate-y-full group-hover:scale-125"
                style={styles.backBg}
              >
              </div>
              <div className="relative h-full p-6 flex items-center justify-center">
                <p 
                  className="text-sm leading-relaxed text-center
                           transition-all duration-700 ease-in-out transform"
                  style={styles.backText}
                >
                  {card.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FlipCards;
