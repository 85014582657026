import React from 'react';
import { Link } from 'react-router-dom';
import FinstaLogo from '../../../../../../Assets/Finsta/finsta_Logo.png'
import FinstaNav from '../FinstaNav';
const IntroFinsta = () => {
  return (
    <div className="min-h-screen bg-white">

      <FinstaNav/>
      {/* Navigation Bar */}


      {/* <nav className="flex flex-col md:flex-row items-center justify-between px-4 md:px-10 py-4 bg-[#E0F7FA] z-50">
       

        
        <div className="flex items-center space-x-4 md:space-x-8">
          <div className="flex items-center gap-2">
            <Link
              to="/services/Finsta/Corebanking"
              className='border-1  px-2 py-3 flex justify-center items-center rounded-full space-x-2 transition duration-300 transform hover:scale-110 hover:bg-[#7afde9] shadow-lg text-center m-auto w-full max-w-xs h-12 bg-black text-white tracking-wide sm:w-24 md:w-36 lg:w-36 xl:w-36'

              style={{ transition: 'color 0.5s ease' }}
              onMouseEnter={(e) => e.currentTarget.style.color = 'black'}
              onMouseLeave={(e) => e.currentTarget.style.color = 'white'}
            >
              Core Banking
            </Link>
          </div>
          
          <div className="flex items-center gap-2">
            <Link
              to="/services/Finsta/Lending"
                className='border-1  px-2 py-3 flex justify-center items-center rounded-full space-x-2 transition duration-300 transform hover:scale-110 hover:bg-[#7afde9] shadow-lg text-center m-auto w-full max-w-xs h-12 bg-black text-white tracking-wide sm:w-24 md:w-36 lg:w-36 xl:w-36'



              style={{ transition: 'color 0.5s ease' }}
              onMouseEnter={(e) => e.currentTarget.style.color = 'black'}
              onMouseLeave={(e) => e.currentTarget.style.color = 'white'}
            >
              Lending
            </Link>
          </div>
        </div>

       
        <div className="flex items-center mb-4 md:mb-0">
          <Link  to="/services/Finsta" ><img src={FinstaLogo} alt="Finsta Logo" className="h-6 sm:h-8" /></Link>
        </div>
      </nav> */}

      {/* Hero Section */}
      <div className="relative z-30 w-full mx-auto px-4 md:px-6 flex flex-col md:flex-row items-center justify-between min-h-[calc(100vh-100px)] bg-[#7afde9] py-8 md:py-0">
        {/* Left Column - Text Content */}
        <div className="w-full md:w-1/2 space-y-6 text-center md:text-left mb-8 md:mb-0">
          <h1 className="text-3xl md:text-5xl font-bold leading-tight drop-shadow-lg text-[#003366]">
            Crafting Tomorrow's<br />Financial Solutions 
          </h1>
          <p className="text-lg md:text-xl font-semibold text-black max-w-xl mx-auto md:mx-0">
            Revolutionizing financial technology with innovative<br className="hidden md:block" />
            solutions that empower businesses and individuals.
          </p>

          <div className="flex items-center justify-center md:justify-start space-x-4">
            <Link
              to="/contact"
              className="bg-black text-white px-6 py-3 rounded-full font-semibold flex items-center space-x-2 transition duration-300 transform hover:scale-105 shadow-lg"
            >
              <span>Talk to our team</span>
            </Link>
          </div>
        </div>

        {/* Right Column with Video and Social Icons */}
        <div className="w-full md:w-1/2 flex flex-col md:flex-row items-center justify-center relative">
          <video
            src="https://ik.imagekit.io/7wqj9br0b/MP4%20Video.mp4?updatedAt=1735195317672"
            className="w-full max-w-[600px] md:max-h-[500px] object-contain"
            autoPlay
            muted
            loop
            playsInline
          />
          
          {/* Social Media Icons - Repositioned for mobile */}
          <div className="flex md:flex-col gap-6 p-4 absolute bottom-0 md:bottom-auto md:right-0 justify-center w-full md:w-auto bg-[#7afde9]/80 md:bg-transparent">
            <Link
              to="https://www.linkedin.com/showcase/finsta"
              target='_blank'
              className="block hover:bg-white/20 p-2 rounded-full transition-all duration-300 ease-in-out transform hover:scale-110 hover:-translate-x-1"
            >
              <img
                src="https://ik.imagekit.io/7wqj9br0b/socialMediaIcons/linkedin.png?updatedAt=1734950185077"
                alt="LinkedIn"
                className="h-6 w-6 object-contain"
              />
            </Link>
            <Link
              to="https://x.com/FinstaSoftware"
              target='_blank'
              className="block hover:bg-white/20 p-2 rounded-full transition-all duration-300 ease-in-out transform hover:scale-110 hover:-translate-x-1"
            >
              <img
                src="https://ik.imagekit.io/7wqj9br0b/socialMediaIcons/twitter.png?updatedAt=1734949833602"
                alt="Twitter"
                className="h-6 w-6 object-contain"
              />
            </Link>
            <Link
              to="https://www.facebook.com/FiNSTASoftware"
              target='_blank'
              className="block hover:bg-white/20 p-2 rounded-full transition-all duration-300 ease-in-out transform hover:scale-110 hover:-translate-x-1"
            >
              <img
                src="https://ik.imagekit.io/7wqj9br0b/socialMediaIcons/facebook.png?updatedAt=1734949833608"
                alt="Facebook"
                className="h-6 w-6 object-contain"
              />
            </Link>
            <Link
              to="https://www.instagram.com/finstaloansoftware/"
              target='_blank'
              className="block hover:bg-white/20 p-2 rounded-full transition-all duration-300 ease-in-out transform hover:scale-110 hover:-translate-x-1"
            >
              <img
                src="https://ik.imagekit.io/7wqj9br0b/socialMediaIcons/instagram.png?updatedAt=1734949833589"
                alt="Instagram"
                className="h-6 w-6 object-contain"
              />
            </Link>
          </div>
        </div>
      </div>

      {/* Background Decorative Elements */}
      <div
        className="absolute inset-0 z-10 pointer-events-none"
        style={{
          background: 'radial-gradient(circle at 75% 25%, rgba(255,255,255,0.1) 0%, transparent 50%)',
          opacity: 0.5
        }}
      />
    </div>
  );
};

export default IntroFinsta;