import React, { useState, useEffect, useRef } from 'react';

// Custom hook for number animation
const useCountAnimation = (end, duration = 2000) => {
  const [count, setCount] = useState(0);
  const elementRef = useRef(null);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // Trigger animation whenever the element enters the viewport
        if (entries[0].isIntersecting) {
          setShouldAnimate(true);
        } else {
          // Reset when element leaves viewport
          setShouldAnimate(false);
          setCount(0);
        }
      },
      { threshold: 0.1 }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (!shouldAnimate) return;

    let startTime = null;
    let requestId;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const percentage = Math.min(progress / duration, 1);
      
      setCount(Math.floor(end * percentage));

      if (progress < duration) {
        requestId = requestAnimationFrame(animate);
      }
    };

    requestId = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(requestId);
  }, [end, duration, shouldAnimate]);

  return [count, elementRef];
};

const StatsSection = () => {
  const [count1, ref1] = useCountAnimation(400, 2000);
  const [count2, ref2] = useCountAnimation(400, 2000);
  const [count3, ref3] = useCountAnimation(114, 2000);
  const [count4, ref4] = useCountAnimation(200, 2000);
  const [count5, ref5] = useCountAnimation(30000, 2000);
  const [count6, ref6] = useCountAnimation(10, 2000);
  const [count7, ref7] = useCountAnimation(60, 2000);

  return (
    <div className="max-w-7xl mx-auto px-4 py-16">
      {/* Main Heading */}
      <div className="text-center mb-16">
        <h1 className="text-5xl font-bold mb-2" style={{color:"black"}}>A platform that delivers</h1>
        <div className="w-48 h-1 bg-green-500 mx-auto" style={{background:"#4caf50"}} ></div>
      </div>

      {/* Top Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        <div ref={ref1} className="bg-teal-100 rounded-lg p-8 text-center" style={{background:" #e0f2f1"}}>
          <div className="text-6xl font-bold mb-2">{count1}</div>
          <div className="text-xl">successful go-lives</div>
        </div>

        <div ref={ref2} className="bg-green-200 rounded-lg p-8 text-center" style={{background:"#a5d6a7"}}>
          <div className="text-6xl font-bold mb-2">{count2}+</div>
          <div className="text-xl">customers</div>
        </div>

        <div ref={ref3} className="bg-purple-200 rounded-lg p-8 text-center" style={{background:"#ce93d8"}}>
          <div className="text-6xl font-bold mb-2">{count3}M</div>
          <div className="text-xl">end users</div>
        </div>

        <div ref={ref4} className="bg-cyan-100 rounded-lg p-8 text-center" style={{background:"#80deea"}}>
          <div className="text-6xl font-bold mb-2">{count4}M</div>
          <div className="text-xl">API calls per day</div>
        </div>
      </div>

      {/* Middle Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6" >
        <div className="bg-teal-400 rounded-lg p-8 text-center" style={{background:"#26a69a"}}>
          <h2 className="text-4xl font-bold text-black mb-4">Go-live in 4 to 9 months</h2>
          <p className="text-xl text-black text-center">60% faster than other providers</p>
        </div>
        
        <div className="bg-green-100 rounded-lg p-8 text-center" style={{background:"#c8e6c9"}}>
          <h2 className="text-4xl font-bold mb-4">A proven track record</h2>
          <p className="text-xl text-center">with a 98% project success rate</p>
        </div>
      </div>

      {/* Bottom Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6" >
        <div ref={ref5} className="bg-purple-100 rounded-lg p-8 text-center" style={{background:" #e1bee7"}}>
          <div className="text-5xl font-bold mb-4">{count5.toLocaleString()}</div>
          <div className="text-lg text-center">possible product configurations</div>
        </div>

        <div ref={ref6} className="bg-teal-100 rounded-lg p-8 text-center" style={{background:"#80deea"}}>
          <div className="text-5xl font-bold mb-4">+{count6} points</div>
          <div className="text-lg text-center">B2B NPS score compared to industry average</div>
        </div>

        <div ref={ref7} className="bg-green-200 rounded-lg p-8 text-center" style={{background:"#a5d6a7"}}>
          <div className="text-5xl font-bold mb-4">~{count7}%</div>
          <div className="text-lg text-center">Fewer resources required to build and launch financial products</div>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;