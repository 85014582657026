import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
// import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';

const AdvantagesSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: "Seamless Integrations",
      description: "Our RESTful APIs and rapid implementation approach removes code duplication and effort during multiple integrations.",
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/Core%20Banking_Slider_section3/47%20-%20Copy.png?updatedAt=1735032789601"
    },
    {
      title: "Adapt & Scale",
      description: "Monitor, debug, and retry mechanisms as business expands. Reduce workloads by minimizing re-coding when processes change.",
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/Core%20Banking_Slider_section3/48.png?updatedAt=1735032789980"
    },
    {
      title: "Agile Development",
      description: "Apply agile principles to build, test, and release new capabilities in days or weeks. Experience faster time-to-market and go-lives.",
      icon: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/Core%20Banking_Slider_section3/50%20-%20Copy.png?updatedAt=1735032790520"
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const goToSlide = (slideIndex) => {
    setCurrentSlide(slideIndex);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-16 bg-gradient-to-br from-[#f4f7f6] to-[#e9f0ef]">
      {/* Header Section */}
      <div className="text-center mb-16">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-xl font-semibold mb-2 text-[#2c3e50] tracking-wider uppercase"
        >
          Next Generation
        </motion.h2>
        <motion.h1
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.7 }}
          className="text-3xl md:text-3xl font-semibold mb-4 text-[#1a2980]"
        >
          Cloud-native Advantages
        </motion.h1>
        <div className="w-24 h-1 bg-[#1a2980] mx-auto rounded-full mb-6"></div>
      </div>

      {/* Carousel Section */}
      <div className="relative max-w-4xl mx-auto">
        {/* Navigation Arrows */}
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={prevSlide}
         className="absolute left-4 top-1/3 transform -translate-y-1/2 z-10 bg-white rounded-full p-2 shadow-lg hover:bg-gray-100 transition-colors duration-200"
          aria-label="Previous Slide"
              >
        <FaChevronLeft   className="w-6 h-6 text-[#1a2980]" />
        
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={nextSlide}
          className="absolute right-0 top-1/3 transform -translate-y-1/2 z-10 bg-white/80 backdrop-blur-sm rounded-full p-3 shadow-lg hover:bg-white/90 transition-all duration-300"
          aria-label="Next Slide"
        >
          <FaChevronRight className="w-6 h-6 text-[#1a2980]" />
        </motion.button>

        {/* Slides Container */}
        <AnimatePresence mode="wait">
          <motion.div
            key={currentSlide}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-2xl shadow-2xl overflow-hidden"
          >
            <div className="p-8 text-center">
              <div className="text-6xl mb-6">  <img src= {slides[currentSlide].icon} alt="icons" className='h-40 m-auto' />   </div>
              <h3 className="text-2xl font-semibold mb-4 text-[#1a2980]">
                {slides[currentSlide].title}
              </h3>
              <p className="text-lg text-[#34495e] leading-relaxed max-w-xl mx-auto">
                {slides[currentSlide].description}
              </p>
            </div>
          </motion.div>
        </AnimatePresence>

        {/* Dots Navigation */}
        <div className="flex justify-center mt-8 space-x-3">
          {slides.map((_, index) => (
            <motion.button
              key={index}
              onClick={() => goToSlide(index)}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
              className={`w-2 h-2 rounded-full transition-colors duration-300 ${
                currentSlide === index
                  ? 'bg-[#1a2980]'
                  : 'bg-[#bdc3c7] hover:bg-[#1a2980]/50'
              }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdvantagesSlider;

// import React, { useState } from 'react';

// const AdvantagesSlider = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   const slides = [
//     {
//       title: "Seamless Integrations",
//       description: "Our RESTful APIs and rapid implementation approach removes code duplication and effort during multiple integrations."
//     },
//     {
//       title: "Adapt & Scale",
//       description: "Monitor, debug, and retry mechanisms as business expands. Reduce workloads by minimizing re-coding when processes change."
//     },
//     {
//       title: "Agile Development",
//       description: "Apply agile principles to build, test, and release new capabilities in days or weeks. Experience faster time-to-market and go-lives."
//     }
//   ];

//   const nextSlide = () => {
//     setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
//   };

//   const prevSlide = () => {
//     setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
//   };

//   const goToSlide = (slideIndex) => {
//     setCurrentSlide(slideIndex);
//   };

//   return (
//     <div className="max-w-7xl mx-auto px-4 py-12">
//       {/* Header Section */}
//       <div className="text-center mb-12">
//         <h2 className="uppercase tracking-wider text-xl font-semibold mb-2 text-indigo-600">
//           Next Generation
//         </h2>
//         <h1 className="text-4xl md:text-5xl font-bold mb-2 text-gray-800">
//           Cloud-native Advantages
//         </h1>
//         <div className="bg-indigo-600 w-24 h-1 mx-auto rounded-full mb-5"></div>
//       </div>

//       {/* Carousel Section */}
//       <div className="relative">
//         {/* Navigation Arrows */}
//         <button
//           onClick={prevSlide}
//           className="absolute left-4 top-1/2 transform -translate-y-1/2 z-10 bg-white rounded-full p-2 shadow-lg hover:bg-gray-100 transition-colors duration-200"
//           aria-label="Previous Slide"
//         >
//           <img src="https://cdn-icons-png.flaticon.com/128/1280/1280875.png" alt="Previous" className="w-6 h-6 text-indigo-600" />
//         </button>
//         <button
//           onClick={nextSlide}
//           className="absolute right-4 top-1/2 transform -translate-y-1/2 z-10 bg-white rounded-full p-2 shadow-lg hover:bg-gray-100 transition-colors duration-200"
//           aria-label="Next Slide"
//         >
//           <img src="https://cdn-icons-png.flaticon.com/128/892/892528.png" alt="Next" className="w-6 h-6 text-indigo-600" />
//         </button>

//         {/* Slides Container */}
//         <div className="overflow-hidden relative">
//           <div
//             className="flex transition-transform duration-500 ease-out"
//             style={{ transform: `translateX(-${currentSlide * 100}%)` }}
//           >
//             {slides.map((slide, index) => (
//               <div key={index} className="w-full flex-shrink-0 px-4">
//                 <div className="bg-white lg:w-3/4 sm:w-full m-auto min-h-[280px] rounded-xl p-8 md:p-12 shadow-lg transition-transform duration-300 hover:scale-[1.02]">
//                   <h3 className="text-indigo-600 text-2xl md:text-3xl font-bold mb-4">
//                     {slide.title}
//                   </h3>
//                   <p className="text-gray-700 text-lg md:text-xl leading-relaxed">
//                     {slide.description}
//                   </p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>

//         {/* Dots Navigation */}
//         <div className="flex justify-center mt-8 space-x-2">
//           {slides.map((_, index) => (
//             <button
//               key={index}
//               onClick={() => goToSlide(index)}
//               className={`w-3 h-3 rounded-full transition-colors duration-200 ${
//                 currentSlide === index ? 'bg-indigo-600' : 'bg-gray-300'
//               }`}
//               aria-label={`Go to slide ${index + 1}`}
//             />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdvantagesSlider;
