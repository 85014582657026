// import React from "react";
// import {
//   FaExchangeAlt,
//   FaShieldAlt,
//   FaBell,
//   FaSitemap,
//   FaChartBar,
//   FaBrain
// } from "react-icons/fa";

// const Allneeds = () => {
//   const cards = [
//     {
//       icon: <FaExchangeAlt className="w-8 h-8" />,
//       title: "Loan Modification",
//       description:
//         "Launch personalized finance products. Set up credit lines, refinancing, custom calculation logic, interest rates, fees, and grace periods via loan automation software.",
//       gradient: "linear-gradient(135deg, #60a5fa 0%, #93c5fd 100%)",
//     },
//     {
//       icon: <FaShieldAlt className="w-8 h-8" />,
//       title: "KYC",
//       description:
//         "Automate the KYC process and ID verification with in-house AI or 3rd-party integrations while staying compliant with regulatory requirements.",
//       gradient: "linear-gradient(135deg, #a78bfa 0%, #c4b5fd 100%)",
//     },
//     {
//       icon: <FaBell className="w-8 h-8" />,
//       title: "Notifications",
//       description:
//         "Set automatic notifications for your clients. Stay connected with borrowers through SMS and email, providing updates, auto-reminders for payments, and required actions.",
//       gradient: "linear-gradient(135deg, #34d399 0%, #6ee7b7 100%)",
//     },
//     {
//       icon: <FaSitemap className="w-8 h-8" />,
//       title: "Custom Workflow",
//       description:
//         "Visualize your workflow and apply it across the system with one click. The smart lending platform supports multiple workflows, ensuring flexibility and seamless operations.",
//       gradient: "linear-gradient(135deg, #fcd34d 0%, #fde68a 100%)",
//     },
//     {
//       icon: <FaChartBar className="w-8 h-8" />,
//       title: "Reports and Dashboards",
//       description:
//         "Generate dashboards with real-time metrics to measure critical KPIs. Export data and customize reports to match your business needs.",
//       gradient: "linear-gradient(135deg, #38bdf8 0%, #7dd3fc 100%)",
//     },
//     {
//       icon: <FaBrain className="w-8 h-8" />,
//       title: "Risk Management",
//       description:
//         "Leverage AI-powered credit risk analysis to score portfolios and deliver actionable insights. Predict NPLs and identify high-risk assets for smarter decision-making.",
//       gradient: "linear-gradient(135deg, #818cf8 0%, #a5b4fc 100%)",
//     },
//   ];

//   return (
//     <div className="relative py-24 overflow-hidden" style={{
//       background: 'white'
//     }}>
//       {/* Background Elements */}
//       <div className="absolute inset-0" style={{
//         background: 'white'
//       }} />
      
//       {/* Header Section */}
//       <div className="relative text-center mb-20 px-4">
//         <h2 className="text-2xl lg:text-2xl font-bold  tracking-tight" style={{
//           color: '#003264',
//           textShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)'
//         }}>
//           All-in-One Digital Lending Platform
//         </h2>
//         <p className="text-lg lg:text-xl ml-96 text-justify mx-auto leading-relaxed" style={{
//           color: '#00796B'
//         }}>
//           Streamline your loan management with a comprehensive,
//           <br />
//           all-in-one software solution to keep every process in check.
//         </p>
//         <div className="w-24 h-1.5 mx-auto rounded-full " style={{
//           background: '#334155'
//         }}></div>
//       </div>

//       {/* Card Section */}
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
//           {cards.map((card, index) => (
//             <div
//               key={index}
//               className="group relative transform transition-all duration-300 hover:-translate-y-2"
//             >
//               <div className="rounded-xl p-8 h-full transition-all duration-300" style={{
//                 background: 'rgba(255, 255, 255, 0.9)',
//                 boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
//                 border: '1px solid rgba(219, 234, 254, 0.7)'
//               }}>
//                 <div className="inline-flex p-3 rounded-xl mb-6" style={{
//                   background: card.gradient,
//                 }}>
//                   {React.cloneElement(card.icon, { className: "w-6 h-6 text-white" })}
//                 </div>
//                 <h3 className="text-xl font-semibold mb-4" style={{
//                   color: '#003263'
//                 }}>
//                   {card.title}
//                 </h3>
//                 <p style={{ color: '#475569' }} className="leading-relaxed">
//                   {card.description}
//                 </p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Allneeds;


import React from "react";
import { 
  FaExchangeAlt, 
  FaShieldAlt, 
  FaBell, 
  FaSitemap, 
  FaChartBar, 
  FaBrain 
} from "react-icons/fa";

const Allneeds = () => {
  const cards = [
    {
      icon:"https://ik.imagekit.io/7wqj9br0b/Lending/all%20in%20one%20software/7.png?updatedAt=1735192760778",
      title: "Loan Modification",
      description:
        "Launch personalized finance products. Set up credit lines, refinancing, custom calculation logic, interest rates, fees, and grace periods via loan automation software.",
      gradient: "linear-gradient(135deg, #60a5fa 0%, #93c5fd 100%)",
    },
    {
      icon: "https://ik.imagekit.io/7wqj9br0b/Lending/all%20in%20one%20software/8.png?updatedAt=1735192760834",
      title: "KYC",
      description:
        "Automate the KYC process and ID verification with in-house AI or 3rd-party integrations while staying compliant with regulatory requirements.",
      gradient: "linear-gradient(135deg, #a78bfa 0%, #c4b5fd 100%)",
    },
    {
      icon: "https://ik.imagekit.io/7wqj9br0b/Lending/LendingIntro/5.png?updatedAt=1735189718612",
      title: "Notifications",
      description:
        "Set automatic notifications for your clients. Stay connected with borrowers through SMS and email, providing updates, auto-reminders for payments, and required actions.",
      gradient: "linear-gradient(135deg, #34d399 0%, #6ee7b7 100%)",
    },
    {
      icon: "https://ik.imagekit.io/7wqj9br0b/Lending/all%20in%20one%20software/9.png?updatedAt=1735192760951",
      title: "Custom Workflow",
      description:
        "Visualize your workflow and apply it across the system with one click. The smart lending platform supports multiple workflows, ensuring flexibility and seamless operations.",
      gradient: "linear-gradient(135deg, #fcd34d 0%, #fde68a 100%)",
    },
    {
      icon: "https://ik.imagekit.io/7wqj9br0b/Lending/all%20in%20one%20software/10.png?updatedAt=1735192761307",
      title: "Reports and Dashboards",
      description:
        "Generate dashboards with real-time metrics to measure critical KPIs. Export data and customize reports to match your business needs.",
      gradient: "linear-gradient(135deg, #38bdf8 0%, #7dd3fc 100%)",
    },
    {
      icon:"https://ik.imagekit.io/7wqj9br0b/Lending/all%20in%20one%20software/11.png?updatedAt=1735192760842",
      title: "Risk Management",
      description:
        "Leverage AI-powered credit risk analysis to score portfolios and deliver actionable insights. Predict NPLs and identify high-risk assets for smarter decision-making.",
      gradient: "linear-gradient(135deg, #818cf8 0%, #a5b4fc 100%)",
    },
  ];

  return (
    <div className="relative py-24 overflow-hidden bg-white">
      {/* Header Section */}
      <div className="text-center mb-20 px-4">
        <h2 className="text-3xl lg:text-4xl font-bold tracking-tight" style={{ 
          color: '#003264',
          textShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)'
        }}>
          All-in-One Digital Lending Platform
        </h2>
        <p className="text-lg lg:text-xl mx-auto leading-relaxed max-w-lg" style={{ 
          color: '#00796B'
        }}>
          Streamline your loan management with a comprehensive,
         All-in-one software solution to keep every process .
        </p>
        <div className="w-24 h-1.5 mx-auto rounded-full" style={{
          backgroundColor: '#334155'
        }}></div>
      </div>

      {/* Card Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card, index) => (
            <div
              key={index}
              className="group relative transform transition-all duration-300 hover:-translate-y-2"
            >
              <div className="rounded-xl p-6 h-full transition-all duration-300" style={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '12px',
                border: '1px solid rgba(219, 234, 254, 0.7)'
              }}>
                <div className="inline-flex p-3 rounded-xl mb-6" >
                  {/* {React.cloneElement(card.icon, { className: "w-6 h-6 text-white" })} */}
                  <img src={card.icon} alt="icon"  className="h-36 w-36 m-auto"/>
                </div>
                <h3 className="text-xl font-semibold mb-2" style={{
                  color: '#003263'
                }}>
                  {card.title}
                </h3>
                <p style={{ color: '#475569' }} className="leading-relaxed">
                  {card.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Allneeds;
