import React, { useState } from 'react';
import InforClient from './InforClient';
import FlipCards from './Flipcards';
import InforAllianceImage from '../../../../Assets/InforPage/LN into image.png'
import Coustomer from '../../../../Assets/InforPage/constact-us-image.png'
import ContactForm from './Contact';
import ServiceCards from './Services';
import Faq from './Faq'
import InforServices from './InforServices';
import PhotoCollage from './PhotoCollab';
import { IoMdCloseCircle } from "react-icons/io";
import { TrendingUp } from 'lucide-react';
import WhatsAppButton from './Whatsapp';
import axios from 'axios';
import { toast } from 'react-toastify';

const Infor = () => {
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });

  // services images 
  const services = [
    { id: 1, src: "https://img.freepik.com/free-photo/business-people-shaking-hands-together_53876-13391.jpg?semt=ais_hybrid" },
    { id: 2, src: "https://img.freepik.com/free-photo/business-concept-with-team-close-up_23-2149151159.jpg?semt=ais_hybrid" },
   { id: 3, src: "https://img.freepik.com/free-photo/business-people-shaking-hands-together_53876-13391.jpg?semt=ais_hybrid" },
    { id: 4, src: "https://img.freepik.com/free-photo/business-concept-with-team-close-up_23-2149151159.jpg?semt=ais_hybrid" },
  ]

  // Client logos array
  const clientLogos = [
    { id: 1, src: "https://ik.imagekit.io/kqumni8l2/Infor_Clients/CMR.png?updatedAt=1735885002372", alt: "Client 1" },
    { id: 2, src: "https://ik.imagekit.io/kqumni8l2/Infor_Clients/PT-GS-Battery.png?updatedAt=1737435858969", alt: "Client 2" },
    { id: 3, src: "https://ik.imagekit.io/kqumni8l2/Infor_Clients/Sanoh.png?updatedAt=1735885002227", alt: "Client 3" },
    { id: 4, src: "https://ik.imagekit.io/kqumni8l2/Infor_Clients/LT.png?updatedAt=1735885002145", alt: "Client 4" },
    { id: 5, src: "https://ik.imagekit.io/kqumni8l2/Infor_Clients/Savio.png?updatedAt=1735885002140", alt: "Client 5" },
    { id: 6, src: "https://ik.imagekit.io/kqumni8l2/Infor_Clients/Saden.png?updatedAt=1735885001834", alt: "Client 6" },
    { id: 7, src: "https://ik.imagekit.io/kqumni8l2/Infor_Clients/Chaiyaboon.png?updatedAt=1735885001776", alt: "Client 7" },
    { id: 8, src: "https://ik.imagekit.io/kqumni8l2/Infor_Clients/AvalGulf.png?updatedAt=1735885001734", alt: "Client 8" },
  ];

  // Flip cards data
  const cards = [
    {
      title: "Card 1",
      description: "This is the detailed description for card 1. Add your content here.",
      gradient: "bg-gradient-to-r from-purple-500 via-pink-500 to-red-500"
    },
    {
      title: "Card 2",
      description: "This is the detailed description for card 2. Add your content here.",
      gradient: "bg-gradient-to-r from-cyan-500 via-teal-500 to-green-500"
    },
    {
      title: "Card 3",
      description: "This is the detailed description for card 3. Add your content here.",
      gradient: "bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500"
    }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Handle form submission here
    try {
      toast.info("Submitting Your Query..."); 
      const response = await axios.post("https://kapil-backend-api.onrender.com/api/infor/InforContactChatBot", formData); 
      if (response.data.success) {
        toast.success("We'll connect you with our Support Team."); 
        setFormData({ 
          name: '',
          companyName: '',
          email: '',
          phoneNumber: '',
          message: ''
        });
      }
    } catch (error) {
      console.error("Error in form submission:", error);
      toast.error("There was an error submitting the Enquiry."); 
    }
    setIsContactOpen(false);
  };

  return (
    <div className="relative">
      {/*Introduction video */}
      <section>
       
        <video 
             src="https://ik.imagekit.io/kqumni8l2/InforIntroVideoFinal.mp4?updatedAt=1737523527046"
            className="w-full h-full object-cover" 
            preload="metadata" 
            autoPlay 
            muted={false} // Set to false to enable audio
            controls
            playsInline
            style={{ width: "100%", height: "100%" }}
        >
            Your browser does not support the video tag.
        </video>
      </section  >
    
      {/* client logo scroll */}
      <div className='bg-gray-500'>
         <InforClient logos={clientLogos} />
     </div>
     
      

      <section className="bg-white p-4 md:p-8">
  <div className="flex flex-col items-center max-w-screen-lg mx-auto">
    <img
      src={InforAllianceImage}
      alt="Infor Alliance partner"
      className="w-full h-auto mb-6 rounded-lg "
    />
    <div className="w-full">
      <FlipCards />
    </div>
  </div>
</section>

{/* Whatsapp icon */}
      <section>
        < WhatsAppButton/>
      </section>



 {/* Contact Button */}
<button
  onClick={(e) => {
    e.stopPropagation();
    setIsContactOpen(!isContactOpen);
  }}
  className="fixed bottom-16 right-6 w-20 h-20  cursor-pointer focus:outline-none z-50"
>
 
        
  <img src="https://ik.imagekit.io/7wqj9br0b/Infor/Robot-unscreen-unscreen.gif?updatedAt=1737000927753" alt="ChatBot"  className='h-24 w-24'/>
</button>

{/* Contact Popup */}
{isContactOpen && (
  <div 
    className="fixed inset-0 flex justify-end items-start  sm:pt-20 p-2 z-50"
    onClick={(e) => {
      if (e.target === e.currentTarget) {
        setIsContactOpen(false);
      }
    }}
    style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
  >
    <div 
      className="rounded-2xl w-full max-w-xs p-5 relative animate-fade-in-up shadow-lg border border-pink-100"
      style={{ 
        background: 'white',
      }}
    >
      <button
        onClick={() => setIsContactOpen(false)}
        className="absolute top-3 right-3 text-pink-400 hover:text-pink-600 transition-colors p-1 rounded-full hover:bg-pink-50"
      >
     
              <IoMdCloseCircle className="h-6 w-6" />
      </button>

      <div className="text-center mb-6">
        <h2 className="text-lg font-semibold  mb-1">Need Assistance?</h2>
        <p className="text-xs text-pink-400 font-medium">Your inquiries are just a message away—let's chat!</p>
      </div>
      
      <form onSubmit={handleSubmit} className="space-y-3">
        <input
          type="text"
          id="name"
          name="name"
          required
          className="w-full px-4 py-2 border border-pink-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-pink-300 text-sm placeholder:text-pink-300"
          placeholder="Enter Your Name *"
          value={formData.name}
          onChange={handleChange}
          style={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            transition: 'all 0.2s ease',
          }}
        />

        <input
          type="text"
          id="companyName"
          name="companyName"
          className="w-full px-4 py-2 border border-pink-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-pink-300 text-sm placeholder:text-pink-300"
          placeholder="Enter Your Company Name"
          value={formData.companyName}
          onChange={handleChange}
          style={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            transition: 'all 0.2s ease',
          }}
        />

        <input
          type="email"
          id="email"
          name="email"
          required
          className="w-full px-4 py-2 border border-pink-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-pink-300 text-sm placeholder:text-pink-300"
          placeholder="Enter Your Email Address *"
          value={formData.email}
          onChange={handleChange}
          style={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            transition: 'all 0.2s ease',
          }}
        />

        <input
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          className="w-full px-4 py-2 border border-pink-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-pink-300 text-sm placeholder:text-pink-300"
          placeholder="Enter Your Phone Number"
          value={formData.phoneNumber}
          onChange={handleChange}
          style={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            transition: 'all 0.2s ease',
          }}
        />

        <textarea
          id="message"
          name="message"
          required
          rows="2"
          className="w-full px-4 py-2 border border-pink-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-pink-300 text-sm placeholder:text-pink-300"
          placeholder="💭 How can we help you? *"
          value={formData.message}
          onChange={handleChange}
          style={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            transition: 'all 0.2s ease',
          }}
        />

        <button
          type="submit"
          className="w-full text-white font-medium py-2.5 px-4 rounded-xl transition-all duration-200 text-sm shadow-md hover:shadow-lg hover:-translate-y-0.5"
          style={{ 
            background: 'red',
          }}
        >
         Click Here
        </button>

        <p className="text-[10px] text-pink-400 text-center mt-2 font-medium">
          We'll get back to you within 24 hours! 🚀
        </p>
      </form>
    </div>
  </div>
  
  
)}
         
      {/* Services Image */}
<section className="py-10 bg-white">
  <div className="max-w-5xl mx-auto text-center px-4">
    <h2 className="text-3xl font-bold mb-6 text-gray-800">Kapil's Infor Services Portfolio</h2>
    <img 
      src="https://ik.imagekit.io/7wqj9br0b/Infor/Infor_Services_Offerings.png?updatedAt=1736999085606" 
      alt="Infor Consulting Services" 
      className="w-full h-auto max-w-4xl text-center m-auto "
    />
  </div>
</section>
    {/* Services in detail */}
      <section>
        <InforServices/>
      </section>




      {/* our services images  */}
      <section>
        <ServiceCards/>
       </section>
     
      <section>

        <PhotoCollage/>
      </section> 
      

      <section>
        <ContactForm/>
      </section>

      <section>
        <Faq />
      </section>

      <section>

      </section>
    </div>
  );
};

export default Infor;



