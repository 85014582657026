import React from 'react'
import IntroLending from './IntroLending'
import Features from './Features'
import Scroll from './Scroll'
import Allneeds from './Allneeds'
const Lending = () => {
  return (
    <div>
      <IntroLending />
      <Features />
      <Scroll />
      <Allneeds/>
    </div>
  )
}

export default Lending
