import React from 'react';
import { Link } from 'react-router-dom';
import Kapil_Technologies_logo from "../../../Assets/Kapil Technlogies Logo.png";
import { RiFacebookCircleFill } from "react-icons/ri";
import { RiInstagramFill } from "react-icons/ri";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io5";
const Footer = () => {


return (
    <div>
      {/* Help Section */}
      <section className="bg-black p-8 text-center">
        <h1 className="text-white text-3xl font-bold">
          How can we Help?
        </h1>
        <p className="text-white my-4 text-center">
          If you have any questions or need assistance, please reach out to us.
        </p>
        <Link to="/contact">
          <button className="border border-white rounded-full px-5 py-2 text-white hover:bg-white hover:text-black transition-colors duration-300">
            Click Here
          </button>
        </Link>
      </section>

      {/* Footer Section */}
      <footer className="bg-gray-100 py-12 lg:ml-10">
        <div className="container mx-auto px-4 h-[200px]">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-10 lg:gap-x-10">
            {/* Company Links */}
            <div>
              <h2 className="text-lg font-semibold mb-2 text-gray-800">Company</h2>
              <ul className="space-y=2">
                <li className="text-sm text-gray-600 hover:text-blue-500 transition-colors">About Us</li>
                <li className="text-sm text-gray-600">Country Sites</li>
                <li className="text-sm text-gray-600">ESG</li>
              {/* <li className="text-sm text-gray-600">Investors</li> */}
               <li className="text-sm text-gray-600"><Link to="https://kapilgroup.com/" target="_blank" >Investors</Link></li>
                <li className="text-sm text-gray-600">Newsroom</li>
                <li className="text-sm text-gray-600">Partner Ecosystem</li>
              </ul>
            </div>

            {/* Articles Section */}
            <div>
              <h2 className="text-lg font-semibold mb-2 text-gray-800">Articles</h2>
              <ul className="space-y=2">
                <li className="text-sm text-gray-600">Articles</li>
                <li className="text-sm text-gray-600">Blog</li>
                <li className="text-sm text-gray-600">Case Studies</li>
                <li className="text-sm text-gray-600">Events</li>
                <li className="text-sm text-gray-600">Podcasts</li>
                {/* <li className="text-sm text-gray=600">White Papers</li> */}
              </ul>
            </div>

            {/* Support Section */}
            <div>
              <h2 className="text-lg font-semibold mb-2 text-gray-800">Support</h2>
              <ul className="space-y=2">
                <li className="text-sm text-gray=600">Accessibility</li>
                <li className="text-sm text-gray=600">Cookie Management</li>
                <li className="text-sm text-gray=600">Privacy</li>
                <li className="text-sm text-gray=600">Terms of Use</li>
              </ul>
            </div>

            {/* Social Media Section */}
            <div className="flex flex-col items-center justify-center">
              {/* Placeholder for Logo */}
              <div className="w-full h-auto mb-[5px] flex items-center justify-center bg-gray-[300] rounded-md p-[10px]">
              <img src={Kapil_Technologies_logo} alt="logo" className='h-16' />
              </div>

              {/* Social Media Icons */}
              {/* <div className="flex space-x-[20px]">
              <Link ><RiFacebookCircleFill  /></Link>
              <Link><RiInstagramFill /></Link>
              <Link><IoLogoLinkedin /></Link>
              <Link><FaSquareXTwitter /></Link>
              </div> */}
            <div className="flex space-x-5 w-40">
  <Link to="https://www.facebook.com/KCS-Technologies-107314782011056/?ref=pages_you_manage" target='_blank' >
                {/* <RiFacebookCircleFill className="text-xl hover:text-blue-500 transition-all duration-300" /> */}
      <img src="https://ik.imagekit.io/7wqj9br0b/socialMediaIcons/facebook.png?updatedAt=1734949833608" alt="Facebook" className='h-4'/>
  </Link>
  <Link to="https://www.instagram.com/kapil_technologies_india/" target='_blank'>
                {/* <RiInstagramFill className="text-xl hover:text-pink-500 transition-all duration-300" /> */}
    <img src="https://ik.imagekit.io/7wqj9br0b/socialMediaIcons/instagram.png?updatedAt=1734949833589" alt="Instagram" className='h-4' />
  </Link>
  <Link to="https://in.linkedin.com/company/kapil-technologies-india" target='_blank'>
                {/* <IoLogoLinkedin className="text-xl hover:text-blue-400 transition-all duration-300" /> */}
    <img src="https://ik.imagekit.io/7wqj9br0b/socialMediaIcons/linkedin.png?updatedAt=1734950185077" alt="Linkedin" className="h-4 hover:text-blue-400 transition-all duration-300"/>
  </Link>
  <Link to="https://x.com/kapiltechnology" target='_blank'>
                {/* <FaSquareXTwitter className="text-xl hover:text-blue-300 transition-all duration-300" /> */}
      <img src="https://ik.imagekit.io/7wqj9br0b/socialMediaIcons/twitter.png?updatedAt=1734949833602" alt="Twitter" className="h-4 hover:text-blue-300 transition-all duration-300" />
   </Link>
    <Link to="http://www.youtube.com/@kapiltechnologies7392" target='_blank'>
                {/* <IoLogoYoutube className="text-xl hover:text-blue-500 transition-all duration-300" /> */}
       <img src="https://ik.imagekit.io/7wqj9br0b/socialMediaIcons/youtube.png?updatedAt=1737103189911" alt="Youtube" className='h-4'/>
  </Link>
              
</div>

            </div>

          </div>
        </div>
      </footer>
    </div>
);
};

export default Footer;