import React from 'react'
import IntroFinsta from './IntroFinsta'
import StatsSection from './StatsSection'
import Cards from './Cards'
import ClientLogo from './ClientLogo'
import ClientTestimonials from './ClientTestimonials'
const Finsta= () => {
  return (
    <div >
      <div >
        <IntroFinsta />
        <ClientLogo />
        <StatsSection />
        <Cards />
        <ClientTestimonials/>
     </div>
    </div>
  )
}

export default Finsta
