import React from 'react'
import IntroCoreBanking from './IntroCoreBanking'
import Features from './Features'
import AdvantagesSlider from './AdvantagesSlider'
import KeyBenefits from './Keybenifits'
const CoreBanking = () => {
  return (
    <div>
      <IntroCoreBanking />
      <Features />
      <AdvantagesSlider />
      <KeyBenefits/>
    </div>
  )
}

export default CoreBanking
