// import React, { useState, useEffect } from 'react';
// import { FaCaretLeft } from "react-icons/fa6";
// import { FaCaretRight } from "react-icons/fa6";
// import Tick from "../../../../../../Assets/tick.png";

// const Carousel = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const [autoplayPaused, setAutoplayPaused] = useState(false);
  
//   const slides = [
//     {
//       image: "/api/placeholder/600/400",
//       title: "Advanced Account Management",
//       description: "Comprehensive tools for precise financial control",
//       features: [
//         { text: "Flexible account settings", tick: Tick },
//         { text: "Dynamic interest rates", tick: Tick },
//         { text: "Overdraft protection", tick: Tick }
//       ]
//     },
//     {
//       image: "/api/placeholder/600/400",
//       title: "Intelligent Product Configuration",
//       description: "Streamline your financial product setup",
//       features: [
//         { text: "Automated compliance", tick: Tick },
//         { text: "Real-time assessment", tick: Tick },
//         { text: "Product customization", tick: Tick }
//       ]
//     },
//     {
//       image: "/api/placeholder/600/400",
//       title: "Strategic Financial Solutions",
//       description: "Empowering financial innovation",
//       features: [
//         { text: "Advanced analytics", tick: Tick },
//         { text: "Predictive modeling", tick: Tick },
//         { text: "Enhanced security", tick: Tick }
//       ]
//     }
//   ];

//   useEffect(() => {
//     if (!autoplayPaused) {
//       const autoplayTimer = setInterval(() => {
//         setCurrentSlide((prev) => (prev + 1) % slides.length);
//       }, 5000);
//       return () => clearInterval(autoplayTimer);
//     }
//   }, [autoplayPaused, slides.length]);

//   const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % slides.length);
//   const prevSlide = () => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);

//   return (
//     <div className="py-8 bg-white" style={{background:""}}>
//       <div className="max-w-5xl mx-auto px-4">
//         {/* Header Section */}
//         <div className="text-center mb-8">
//           <h2 className="text-3xl font-semibold mb-2 " style={{ color: "#010164" }}>
//             What You'll Experience
//           </h2>
//           <h1 className="text-xl font-bold text-gray-900 mb-3" style={{color:"black"}}>
//             Single loan operating system
//           </h1>
//           <div
//             className="w-24 h-1 mx-auto rounded-full"
//             style={{ background: "#141485" }}
//           ></div>
//         </div>

//         {/* Carousel Container */}
//         <div
//           className="relative bg-white rounded-xl overflow-hidden shadow-lg"
//           onMouseEnter={() => setAutoplayPaused(true)}
//           onMouseLeave={() => setAutoplayPaused(false)}
//         >
//           {/* Navigation Buttons */}
//           <button
//             onClick={prevSlide}
//             className="absolute left-0 top-1/2 transform -translate-y-1/2 z-20 p-2 bg-white/90 rounded-full shadow-md hover:bg-gray-50 transition-all duration-300"
//             aria-label="Previous Slide"
//           >
//             <FaCaretLeft className="w-5 h-5 text-gray-700" />
//           </button>
          
//           <button
//             onClick={nextSlide}
//             className="absolute  right-2 top-1/2 transform -translate-y-1/2 z-20 p-2 bg-white/90 rounded-full shadow-md hover:bg-gray-50 transition-all duration-300"
//             aria-label="Next Slide"
//           >
//             <FaCaretRight className="w-5 h-5 text-gray-700" />
//           </button>

//           {/* Slides */}
//           <div
//             className="flex transition-transform duration-500 ease-in-out"
//             style={{ transform: `translateX(-${currentSlide * 100}%)` }}
//           >
//             {slides.map((slide, index) => (
//               <div
//                 key={index}
//                 className="min-w-full grid grid-cols-1 md:grid-cols-2 gap-6 p-6"
//               >
//                 {/* Content */}
//                 <div className="space-y-4">
//                   <div>
//                     <h2 className="text-xl font-bold text-gray-900 mb-2" style={{color:"#001F3F"}}>
//                       {slide.title}
//                     </h2>
//                     <p className="text-md text-gray-600" style={{color:"#00796B"}} >{slide.description}</p>
//                   </div>
                  
//                   <ul className="flex flex-col px-10  space-y-3">
//                     {slide.features.map((feature, idx) => (
//                       <li key={idx} className="flex items-center space-x-2 text-gray-800">
//                         <img src={feature.tick} alt="tick" className="w-4 h-4" />
//                         <span className="text-md">{feature.text}</span>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
                
//                 {/* Image */}
//                 <div className="relative h-48 md:h-64 rounded-lg overflow-hidden">
//                   <img
//                     src={slide.image}
//                     alt={`${slide.title} illustration`}
//                     className="w-full h-full object-cover"
//                   />
//                 </div>
//               </div>
//             ))}
//           </div>

//           {/* Slide Indicators */}
//           <div className="absolute bottom-3 left-1/2 transform -translate-x-1/2 flex space-x-2 bg-white/80 px-3 py-1.5 rounded-full">
//             {slides.map((_, index) => (
//               <button
//                 key={index}
//                 className={`w-1.5 h-1.5 rounded-full transition-all duration-300 ${
//                   index === currentSlide
//                     ? 'w-4 bg-blue-600'
//                     : 'bg-gray-300'
//                 }`}
//                 onClick={() => setCurrentSlide(index)}
//                 aria-label={`Go to slide ${index + 1}`}
//               />
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Carousel;



// import React, { useState, useEffect } from 'react';
// import { ChevronLeft, ChevronRight } from 'lucide-react';

// const Carousel = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const [autoplayPaused, setAutoplayPaused] = useState(false);
  
//   const slides = [
//     {
//       image: "/api/placeholder/600/400",
//       title: "Advanced Account Management",
//       description: "Comprehensive tools for precise financial control",
//       features: [
//         { text: "Flexible account settings" },
//         { text: "Dynamic interest rates" },
//         { text: "Overdraft protection" }
//       ]
//     },
//     {
//       image: "/api/placeholder/600/400",
//       title: "Intelligent Product Configuration",
//       description: "Streamline your financial product setup",
//       features: [
//         { text: "Automated compliance" },
//         { text: "Real-time assessment" },
//         { text: "Product customization" }
//       ]
//     },
//     {
//       image: "/api/placeholder/600/400",
//       title: "Strategic Financial Solutions",
//       description: "Empowering financial innovation",
//       features: [
//         { text: "Advanced analytics" },
//         { text: "Predictive modeling" },
//         { text: "Enhanced security" }
//       ]
//     }
//   ];

//   useEffect(() => {
//     if (!autoplayPaused) {
//       const autoplayTimer = setInterval(() => {
//         setCurrentSlide((prev) => (prev + 1) % slides.length);
//       }, 5000);
//       return () => clearInterval(autoplayTimer);
//     }
//   }, [autoplayPaused, slides.length]);

//   const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % slides.length);
//   const prevSlide = () => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);

//   return (
//     <div className="py-8 sm:py-12 lg:py-16 bg-gradient-to-b from-white to-gray-50">
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         {/* Header Section */}
//         <div className="text-center mb-8 sm:mb-12">
//           <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-3 text-[#010164] tracking-tight">
//             What You'll Experience
//           </h2>
//           <h3 className="text-lg sm:text-xl lg:text-2xl font-semibold text-gray-900 mb-4">
//             Single loan operating system
//           </h3>
//           <div className="flex items-center justify-center gap-2">
//             <div className="w-16 h-1 rounded-full bg-[#141485]"></div>
//             <div className="w-4 h-1 rounded-full bg-[#141485] opacity-70"></div>
//             <div className="w-2 h-1 rounded-full bg-[#141485] opacity-40"></div>
//           </div>
//         </div>

//         {/* Carousel Container */}
//         <div
//           className="relative bg-white rounded-2xl overflow-hidden shadow-2xl"
//           onMouseEnter={() => setAutoplayPaused(true)}
//           onMouseLeave={() => setAutoplayPaused(false)}
//         >
//           {/* Navigation Buttons */}
//           <button
//             onClick={prevSlide}
//             className="absolute left-2 sm:left-4 top-1/2 transform -translate-y-1/2 z-20 p-2 sm:p-3
//               bg-white/90 rounded-full shadow-lg hover:shadow-xl hover:bg-gray-50
//               transition-all duration-300 backdrop-blur-sm"
//             aria-label="Previous Slide"
//           >
//             <ChevronLeft className="w-4 h-4 sm:w-5 sm:h-5 text-gray-700" />
//           </button>
          
//           <button
//             onClick={nextSlide}
//             className="absolute right-2 sm:right-4 top-1/2 transform -translate-y-1/2 z-20 p-2 sm:p-3
//               bg-white/90 rounded-full shadow-lg hover:shadow-xl hover:bg-gray-50
//               transition-all duration-300 backdrop-blur-sm"
//             aria-label="Next Slide"
//           >
//             <ChevronRight className="w-4 h-4 sm:w-5 sm:h-5 text-gray-700" />
//           </button>

//           {/* Slides */}
//           <div
//             className="flex transition-transform duration-700 ease-out"
//             style={{ transform: `translateX(-${currentSlide * 100}%)` }}
//           >
//             {slides.map((slide, index) => (
//               <div
//                 key={index}
//                 className="min-w-full grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8 lg:gap-12 p-4 sm:p-6 lg:p-8"
//               >
//                 {/* Content */}
//                 <div className="space-y-6 flex flex-col justify-center">
//                   <div className="space-y-3">
//                     <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold text-[#001F3F] tracking-tight">
//                       {slide.title}
//                     </h2>
//                     <p className="text-sm sm:text-base lg:text-lg text-[#00796B]">
//                       {slide.description}
//                     </p>
//                   </div>
                  
//                   <ul className="space-y-4 pl-4">
//                     {slide.features.map((feature, idx) => (
//                       <li key={idx} className="flex items-center gap-3 text-gray-800 group">
//                         <div className="w-5 h-5 rounded-full bg-green-100 flex items-center justify-center
//                           group-hover:bg-green-200 transition-colors duration-300">
//                           <svg className="w-3 h-3 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
//                           </svg>
//                         </div>
//                         <span className="text-sm sm:text-base">{feature.text}</span>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
                
//                 {/* Image */}
//                 <div className="relative h-48 sm:h-64 lg:h-full rounded-xl overflow-hidden shadow-lg
//                   transform transition-transform duration-500 hover:scale-102">
//                   <img
//                     src={slide.image}
//                     alt={`${slide.title} illustration`}
//                     className="w-full h-full object-cover"
//                   />
//                   <div className="absolute inset-0 bg-gradient-to-t from-black/10 to-transparent"></div>
//                 </div>
//               </div>
//             ))}
//           </div>

//           {/* Slide Indicators */}
//           <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2 bg-white/90
//             px-3 py-2 rounded-full shadow-lg backdrop-blur-sm">
//             {slides.map((_, index) => (
//               <button
//                 key={index}
//                 className={`h-1.5 rounded-full transition-all duration-300 ${
//                   index === currentSlide
//                     ? 'w-6 bg-blue-600'
//                     : 'w-1.5 bg-gray-300 hover:bg-gray-400'
//                 }`}
//                 onClick={() => setCurrentSlide(index)}
//                 aria-label={`Go to slide ${index + 1}`}
//               />
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Carousel;



import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [autoplayPaused, setAutoplayPaused] = useState(false);
  
  const slides = [
    {
      image: "https://ik.imagekit.io/7wqj9br0b/Lending/Lending_scroll/rb_5638.png?updatedAt=1735186648061",
      title: "Advanced Account Management",
      description: "Comprehensive tools for precise financial control",
      features: [
        { text: "Flexible account settings" },
        { text: "Dynamic interest rates" },
        { text: "Overdraft protection" }
      ]
    },
    {
      image: "https://ik.imagekit.io/7wqj9br0b/Lending/Lending_scroll/ProductConfiguration.jpg?updatedAt=1734933724974",
      title: "Intelligent Product Configuration",
      description: "Streamline your financial product setup",
      features: [
        { text: "Automated compliance" },
        { text: "Real-time assessment" },
        { text: "Product customization" }
      ]
    },
    {
      image: "https://ik.imagekit.io/7wqj9br0b/Lending/Lending_scroll/people-office-analyzing-checking-finance-graphs.jpg?updatedAt=1735186363802",
      title: "Strategic Financial Solutions",
      description: "Empowering financial innovation",
      features: [
        { text: "Advanced analytics" },
        { text: "Predictive modeling" },
        { text: "Enhanced security" }
      ]
    }
  ];

  useEffect(() => {
    if (!autoplayPaused) {
      const autoplayTimer = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
      }, 5000);
      return () => clearInterval(autoplayTimer);
    }
  }, [autoplayPaused, slides.length]);

  const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % slides.length);
  const prevSlide = () => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);

  return (
    <div className="py-8 sm:py-12 lg:py-16 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-8 sm:mb-12">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-3" style={{ color: "#010164" }}>
            What You'll Experience
          </h2>
          <h3 className="text-lg sm:text-xl lg:text-2xl font-semibold text-gray-900 mb-4">
            Single loan operating system
          </h3>
          <div className='h-1 w-32 border rounded-lg text-center m-auto' style={{background:"#141485"}}></div>
          {/* <div className="flex items-center justify-center gap-2">
            <div className="w-16 h-1 rounded-full" style={{ backgroundColor: "#141485" }}></div>
            <div className="w-4 h-1 rounded-full" style={{ backgroundColor: "#141485", opacity: 0.7 }}></div>
            <div className="w-2 h-1 rounded-full" style={{ backgroundColor: "#141485", opacity: 0.4 }}></div>
          </div> */}
        </div>

        {/* Carousel Container */}
        <div 
          className="relative bg-white rounded-2xl overflow-hidden"
          onMouseEnter={() => setAutoplayPaused(true)}
          onMouseLeave={() => setAutoplayPaused(false)}
        >
          {/* Navigation Buttons */}
          <button 
            onClick={prevSlide} 
            className="absolute left-2 sm:left-4 top-1/2 transform -translate-y-1/2 z-20 p-2 sm:p-3 
              bg-white/90 rounded-full shadow-lg hover:shadow-xl hover:bg-gray-50 
              transition-all duration-300 backdrop-blur-sm"
            aria-label="Previous Slide"
          >
            <ChevronLeft className="w-4 h-4 sm:w-5 sm:h-5" style={{ color: "#4B5563" }} />
          </button>
          
          <button 
            onClick={nextSlide} 
            className="absolute right-2 sm:right-4 top-1/2 transform -translate-y-1/2 z-20 p-2 sm:p-3 
              bg-white/90 rounded-full shadow-lg hover:shadow-xl hover:bg-gray-50 
              transition-all duration-300 backdrop-blur-sm"
            aria-label="Next Slide"
          >
            <ChevronRight className="w-4 h-4 sm:w-5 sm:h-5" style={{ color: "#4B5563" }} />
          </button>

          {/* Slides */}
          <div 
            className="flex transition-transform duration-700 ease-out"
            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
          >
            {slides.map((slide, index) => (
              <div 
                key={index} 
                className="min-w-full grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8 lg:gap-12 p-4 sm:p-6 lg:p-8"
              >
                {/* Content */}
                <div className="space-y-6 flex  h-80  flex-col justify-center">
                  <div className="space-y-3">
                    <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold" style={{ color: "#001F3F" }}>
                      {slide.title}
                    </h2>
                    <p className="text-sm sm:text-base lg:text-lg" style={{ color: "#00796B" }}>
                      {slide.description}
                    </p>
                  </div>
                  
                  <ul className="space-y-4 pl-4">
                    {slide.features.map((feature, idx) => (
                      <li key={idx} className="flex items-center gap-3 text-gray-800 group">
                        <div className="w-5 h-5 rounded-full bg-green-100 flex items-center justify-center 
                          group-hover:bg-green-200 transition-colors duration-300">
                          <svg className="w-3 h-3 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <span className="text-sm sm:text-base">{feature.text}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                
                {/* Image */}
                <div className="relative h-48 sm:h-64 lg:h-full rounded-xl overflow-hidden 
                  transform transition-transform duration-500 hover:scale-[1.02]">
                  <img
                    src={slide.image}
                    alt={`${slide.title} illustration`}
                    className=" h-96 w-96 m-auto  object-contain"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/10 to-transparent"></div>
                </div>
              </div>
            ))}
          </div>

          {/* Slide Indicators */}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-[50%] flex gap-[0.5rem] bg-white/90 
            px-[0.75rem] py-[0.5rem] rounded-full shadow-lg backdrop-blur-sm">
            {slides.map((_, index) => (
              <button
                key={index}
                style={{
                  height: '0.375rem', // Equivalent to h-[1.5] in Tailwind
                  borderRadius: '9999px', // Full rounding
                  transition: 'all 0.3s',
                  width: index === currentSlide ? '1.5rem' : '0.375rem', // Conditional width
                  backgroundColor: index === currentSlide ? '#2563EB' : '#D1D5DB' // Conditional color
                }}
                onClick={() => setCurrentSlide(index)}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
