import AWS from "../../componentLayer/pages/services/AWS";
import BusinessConsulting from "../../componentLayer/pages/services/BusinessConsulting";
import KapilTechnologies360Degrees from "../../componentLayer/pages/services/KapilTechnologies360Degrees";
import Google_Colud_Services from "../../componentLayer/pages/services/Google_Colud_Services";
import Infor from "../../componentLayer/pages/services/Infor";
import Microsoft from "../../componentLayer/pages/services/Microsoft";
import Oddo from "../../componentLayer/pages/services/Oddo";
import Oracle_ID_Edwards from "../../componentLayer/pages/services/Oracle_ID_Edwards";
import Easychits from "../../componentLayer/pages/services/Products/Easychits";
import Finsta from "../../componentLayer/pages/services/Products/Finsta";
import IFCA from "../../componentLayer/pages/services/Products/IFCA";
import MES from "../../componentLayer/pages/services/Products/MES";
import MPDV from "../../componentLayer/pages/services/Products/MPDV";
import SampleTemp from "../../componentLayer/pages/services/SampleTemp";
import SAP from "../../componentLayer/pages/services/SAP";
import PLM from "../../componentLayer/pages/services/PLM";
import SupplyChainManagement from "../../componentLayer/pages/services/SupplyChainManagement";
import ESG from "../../componentLayer/pages/services/ESG";
import ITTransformation from "../../componentLayer/pages/services/ITTransformation";
import ApplicationManagement from "../../componentLayer/pages/services/ApplicationManagement";
import Testing from "../../componentLayer/pages/services/Testing";
import Development from "../../componentLayer/pages/services/servicesInnerPages/Development";

import FinstaNew from '../../componentLayer/pages/services/Products/Finsta/FinstaHome/Finsta'
import {FinstaRouter} from '../../routerLayer/Finsta/finsta.router'
import Infornew from '../../componentLayer/pages/services/Infor/Infor'

export const serviceRouter = [
  {
    path: "digital",
    element: <SampleTemp />,
  },
  {
    path: "oracle_and_id_edwards",
    element: <Oracle_ID_Edwards />,
  },
  {
    path: "sap",
    element: <SAP />,
  },
  {
    path: "infor",
    element: <Infornew />,
  },
  {
    path: "microsoft",
    element: <Microsoft />,
  },
  {
    path: "odoo",
    element: <Oddo />,
  },
  {
    path: "ptc",
    element: <BusinessConsulting />,
  },
  {
    path: "aws",
    element: <AWS />,
  },
  {
    path: "googlecloud",
    element: <Google_Colud_Services />,
  },
  {
    path: "customer_relationship_management",
    element: <BusinessConsulting />,
  },
  {
    path: "manufacturing_execution_systems",
    element: <BusinessConsulting />,
  },
  {
    path: "product_lifecycle_management",
    element: <PLM />,
  },
  {
    path: "supply_chain_management",
    element: <SupplyChainManagement />,
  },
  {
    path: "environmental_social_governance",
    element: <ESG />,
  },
  {
    path: "it_transformation",
    element: <ITTransformation />,
  },
  {
    path: "applications_management",
    element: <ApplicationManagement />,
  },
  {
    path: "testing",
    element: <Testing />,
  },
  {
    path: "development",
    element: <Development />,
  },
  {
    path: "hexabuilt",
    element: <BusinessConsulting />,
  },
  {
    path: "qrra",
    element: <BusinessConsulting />,
  },
  {
    path: "finsta",
    // element: <FinstaNew />,
    children: [...FinstaRouter],
  },

  {
    path: "easychits",
    element: <Easychits />,
  },
  {
    path: "ifca",
    element: <IFCA />,
  },
  {
    path: "mes",
    element: <MES />,
  },
  {
    path: "mpdv",
    element: <MPDV />,
  },
  {
    path: "spa",
    element: <BusinessConsulting />,
  },
  {
    path: "mobillor",
    element: <BusinessConsulting />,
  },
];
