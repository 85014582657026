
import React from 'react'
import Contact from '../../componentLayer/pages/Contact'
import AnotherContactus from '../../componentLayer/pages/AnotherContactUs'
export const contactRouter = [
  {
    // path : "contact",
    index: true,
    element: <AnotherContactus />,
  },
];
