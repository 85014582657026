// import couple from "../../../Assets/blogimg3.png";
import couple from '../../../Assets/Services_Real.jpg'
import { Link } from "react-router-dom";
import { DropdownIcon } from "./Navbar";
const Services = ({
  servicesLinks,
  setActive,
  smallScreenSubactive,
  setSmallScreenSubActive,
  setOpen,
  linkClicked,
}) => {
  return (
    <>
      <div className="absolute  top-0 xl:block hidden z-10 shadow-xl ">
        <div className="bg-[#fff] p-5  grid grid-cols-4 gap-5 xl:px-5">
          {/*  services 4 parts */}
          <div className="px-8 ">
            <div className="border-dashed border border-light-blue-500 p-1">
              <div className="imagehover">
                <img src={couple} alt="hg" className="" />
                <div className="overlay"></div>
              </div>
              <h1 className="text-lg  my-2 mx-2" style={{color:"#003366"}}>
                {" "}
                Beyond The Ordinary
              </h1>
              <p className="text-sm mb-1 mx-2 " style={{color:"black"}}>
                {" "}
                Service is not about the tools we use, but how creatively we use them to benefit others
              </p>
            </div>
          </div>

          <div>
            <h6 className=" mb-4  text-base font-bold text-[#151515] w-[400px]">
              {servicesLinks.enterprise_technologies?.name}
            </h6>
            <div className="grid grid-cols-2 w-[480px]">
              <div>
                {servicesLinks.enterprise_technologies?.links?.subLink1.map(
                  (item) => (
                    <li className="no-underline list-none mb-2 ">
                      <Link
                        to={item.link}
                        className="text-sm text-[#333] text-left hover:text-bluecolor"
                        onClick={
                          () => {
                            setActive("");
                            linkClicked();
                          } //this is same page reload it'll come from starting
                        }
                      >
                        {item.name}
                      </Link>
                    </li>
                  )
                )}{" "}
              </div>
              <div>
                {servicesLinks.enterprise_technologies?.links?.subLink2.map(
                  (item) => (
                    <li className="no-underline list-none mb-2">
                      <Link
                        to={item.link}
                        className="text-sm text-[#333]  hover:text-bluecolor"
                        onClick={() => {
                          setActive("");
                          linkClicked();
                        }}
                      >
                        {item.name}
                      </Link>
                    </li>
                  )
                )}
              </div>
            </div>
          </div>
          {/* <div>
            <h6 className=" mb-4  text-base font-bold text-[#151515]">
              {servicesLinks.Services?.name}
            </h6>

            <div className="grid grid-cols-2">
              <div>
                {servicesLinks.Services?.links?.subLink1.map((item) => (
                  <li className="no-underline list-none mb-2">
                    <Link
                      to={item.link}
                      className="text-sm text-[#333]  hover:text-bluecolor"
                      onClick={() => {
                        setActive("");
                        linkClicked();
                      }}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}{" "}
              </div>
              <div></div>
            </div>
          </div> */}
          <div className="ml-56">
            <h6 className=" mb-4  text-base font-bold text-[#151515]">
              {" "}
              {servicesLinks.Products?.name}
            </h6>
            <div className="grid grid-cols-2 w-[300px]">
              <div>
                {servicesLinks.Products?.links?.subLink1.map((item) => (
                  <li className="no-underline list-none mb-2">
                    <Link
                      to={item.link}
                      className="text-sm text-[#333]  hover:text-bluecolor"
                      onClick={() => {
                        setActive("");
                        linkClicked();
                      }}
                     
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}{" "}
              </div>
              <div>
                {servicesLinks.Products?.links?.subLink2.map((item) => (
                  <li className="no-underline list-none mb-2">
                    <Link
                      to={item.link}
                      className="text-sm text-[#333]  hover:text-bluecolor"
                      onClick={() => {
                        setActive("");
                        linkClicked();
                      }}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile screen*/}

      <div className="xl:hidden block">
        <h4
          className="py-2 pl-3  xl:pr-0  pr-5 flex items-center justify-between text-sm "
          onClick={() => {
            if (smallScreenSubactive !== "enterprise_technologiessublink") {
              setSmallScreenSubActive("enterprise_technologiessublink");
            } else {
              setSmallScreenSubActive("");
            }
          }}
        >
          {servicesLinks.enterprise_technologies?.name}
          <span
            className="xl:mt-1 xl:ml-2 inline"
            onClick={() => {
              if (smallScreenSubactive !== "enterprise_technologiessublink") {
                setSmallScreenSubActive("enterprise_technologiessublink");
              } else {
                setSmallScreenSubActive("");
              }
            }}
          >
            <DropdownIcon
              isOpen={smallScreenSubactive == "enterprise_technologiessublink"}
            />
          </span>
        </h4>
        {smallScreenSubactive === "enterprise_technologiessublink" && (
          <div>
            {servicesLinks.enterprise_technologies?.links?.subLink1.map(
              (item) => (
                <li className="no-underline list-none mb-2">
                  <Link
                    to={item.link}
                    className="text-sm text-gray-400  hover:text-bluecolor ml-5"
                    onClick={() => setOpen("")}
                  >
                    {item.name}
                  </Link>
                </li>
              )
            )}
            {servicesLinks.enterprise_technologies?.links?.subLink2.map(
              (item) => (
                <li className="no-underline list-none mb-2">
                  <Link
                    to={item.link}
                    className="text-sm text-gray-400 my-2.5 hover:text-bluecolor ml-5"
                    onClick={() => setOpen("")}
                  >
                    {item.name}
                  </Link>
                </li>
              )
            )}
          </div>
        )}
        {/* <h4
          className="py-2 pl-3  xl:pr-0  pr-5 flex items-center justify-between text-sm"
          onClick={() => {
            if (smallScreenSubactive !== "servicessublink") {
              setSmallScreenSubActive("servicessublink");
            } else {
              setSmallScreenSubActive("");
            }
          }}
        >
          {servicesLinks.Services?.name}
          <span
            className="xl:mt-1 xl:ml-2 inline"
            onClick={() => {
              if (smallScreenSubactive !== "servicessublink") {
                setSmallScreenSubActive("servicessublink");
              } else {
                setSmallScreenSubActive("");
              }
            }}
          >
            <DropdownIcon isOpen={smallScreenSubactive == "servicessublink"} />
          </span>
        </h4>
        {smallScreenSubactive === "servicessublink" && (
          <div>
            {servicesLinks.Services?.links?.subLink1.map((item) => (
              <li className="no-underline list-none mb-2">
                <Link
                  to={item.link}
                  className="text-sm text-gray-400  hover:text-bluecolor ml-5"
                  onClick={() => setOpen("")}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </div>
        )} */}
        <h4
          className="py-2 pl-3  xl:pr-0  pr-5 flex items-center justify-between text-sm"
          onClick={() => {
            if (smallScreenSubactive !== "productssublink") {
              setSmallScreenSubActive("productssublink");
            } else {
              setSmallScreenSubActive("");
            }
          }}
        >
          {servicesLinks.Products?.name}
          <span
            className="xl:mt-1 xl:ml-2 inline"
            onClick={() => {
              if (smallScreenSubactive !== "productssublink") {
                setSmallScreenSubActive("productssublink");
              } else {
                setSmallScreenSubActive("");
              }
            }}
          >
            <DropdownIcon isOpen={smallScreenSubactive == "productssublink"} />
          </span>
        </h4>
        {smallScreenSubactive === "productssublink" && (
          <div>
            {servicesLinks.Products?.links?.subLink1.map((item) => (
              <li className="no-underline list-none mb-2">
                <Link
                  to={item.link}
                  className="text-sm text-gray-400  hover:text-bluecolor ml-5"
                  onClick={() => setOpen("")}
                >
                  {item.name}
                </Link>
              </li>
            ))}
                {servicesLinks.Products?.links?.subLink2.map((item) => (
              <li className="no-underline list-none mb-2">
                <Link
                  to={item.link}
                  className="text-sm text-gray-400  hover:text-bluecolor ml-5"
                  onClick={() => setOpen("")}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Services;
