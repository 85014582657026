import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import FinstaLogo from '../../../../../Assets/Finsta/finsta_Logo.png'
const FinstaNav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navLinks = [
    { text: "Core Banking", to: "/services/Finsta/Corebanking" },
    { text: "Lending", to: "/services/Finsta/Lending" }
  ];

  const NavLink = ({ to, children }) => (
    <Link
      to={to}
      className="border-1 px-4 py-2 flex justify-center items-center rounded-full 
                 transition duration-300 transform hover:scale-105 hover:bg-[#7afde9]
                 shadow-lg text-white bg-black
                 w-full sm:w-32 md:w-36 lg:w-40
                 text-center text-sm sm:text-base"
      style={{ transition: 'all 0.5s ease' }}
      onMouseEnter={(e) => e.currentTarget.style.color = 'black'}
      onMouseLeave={(e) => e.currentTarget.style.color = 'white'}
    >
      {children}
    </Link>
  );

  return (
    <nav className="relative bg-[#E0F7FA] w-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16 sm:h-20">
         

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-4 lg:space-x-8">
            {navLinks.map((link) => (
              <NavLink key={link.text} to={link.to}>
                {link.text}
              </NavLink>
            ))}
                  </div>
                  
                   {/* Logo - Always visible */}
          <div className="flex-shrink-0">
            <Link to="/services/Finsta">
              <img 
                src={FinstaLogo} 
                alt="Finsta Logo" 
                className="h-6 sm:h-8 md:h-10"
              />
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-black hover:text-gray-500 hover:bg-gray-100"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <div className="md:hidden absolute top-16 left-0 right-0 bg-[#E0F7FA] z-50 shadow-lg">
          <div className="px-4 pt-2 pb-4 space-y-3">
            {navLinks.map((link) => (
              <div key={link.text} className="w-full">
                <NavLink to={link.to}>
                  {link.text}
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default FinstaNav;