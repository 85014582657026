import React from "react";
import Finsta from '../../componentLayer/pages/services/Products/Finsta/FinstaHome/Finsta.jsx'
import CoreBanking from "../../componentLayer/pages/services/Products/Finsta/Corebanking/CoreBanking.jsx";
import Lending from "../../componentLayer/pages/services/Products/Finsta/Lending/Lending.jsx";
export const FinstaRouter = [
  {
    index: true,
    element: <Finsta />,
  },

  {
    children: [
      { index: true, element: <Finsta /> },
      { path: "CoreBanking", element: <CoreBanking /> },
      { path: "Lending", element: <Lending /> },
    ],
  },
];
