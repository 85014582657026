import React, { useRef, useEffect } from 'react';

const clientLogos = [
  { id: 1, name: 'TechCorp', logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/vindprapani-300x150.png?updatedAt=1734504180783' },
  { id: 2, name: 'InnovateInc', logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/Viswari-marketing-300x150.png?updatedAt=1734504180477' },
  { id: 3, name: 'GlobalSolutions', logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/svb-nidhi-1-300x150.png?updatedAt=1734504180431' },
  { id: 4, name: 'FutureTech', logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/shivasai-300x150.png?updatedAt=1734504180272' },
  { id: 5, name: 'QuantumSystems', logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/raghuvaran-300x150.png?updatedAt=1734504178158' },
  { id: 6, name: 'DataDynamics', logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/sai-prathiba-logo-300x150.png?updatedAt=1734504177650' },
  { id: 7, name: 'IntelliSoft', logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/Aalamaralogo-1-300x150.png?updatedAt=1734504177786' },
  { id: 8, name: 'CloudNative', logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/maatruka-1-300x150.png?updatedAt=1734504177624' },
  { id: 9, name: 'NextGen', logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/sftcl_logo-300x150.png?updatedAt=1734504177524' },
  { id: 10, name: 'DigitalFrontier', logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/mithralogo-300x150.png?updatedAt=1734504177562' },
  { id: 11, name: 'CloudNative', logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/Msg-300x150.png?updatedAt=1734504177519' },
  { id: 12, name: 'NextGen', logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/Maruthaya-1.png1_-1.png?updatedAt=1734504177528' },
  { id: 13, name: 'DigitalFrontier', logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/maharsha-1-300x150.png?updatedAt=1734504177427' },
   { id: 14, name: 'DigitalFrontier', logo: 'https://ik.imagekit.io/7wqj9br0b/Finsta_Client_png/credset_logo1-300x150.png?updatedAt=1734504177418' }
];

const ClientLogo = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let animationFrameId;
    let scrollPosition = 0;

    const animate = () => {
      if (scrollContainer) {
        scrollPosition -= 1; // Adjust speed here
        scrollContainer.style.transform = `translateX(${scrollPosition}px)`;

        // Reset scroll when it exceeds the total width
        if (Math.abs(scrollPosition) >= scrollContainer.scrollWidth / 2) {
          scrollPosition = 0;
        }
      }
      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <div className="relative w-full overflow-hidden py-16" style={{background:"#fffff"}}>
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          {/* <h2 className="text-4xl font-bold text-gray-800 mb-4
            bg-clip-text text-transparent" style={{color:" #003366"}}>
            Trusted by Industry Leaders
          </h2> */}

          <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent" style={{ color: "#003366" }}>
  Trusted <span className='italic'>by</span>   Industry Leaders
</h2>

          <p className="text-xl text-gray-600 max-w-xl mx-auto text-center" style={{color:"#4A4A4A"}}>
            We proudly showcase a selection of our esteemed clients who have experienced exceptional service and satisfaction
          </p>
        </div>

        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 w-24 z-10 
            bg-gradient-to-r from-white to-transparent"></div>
          <div className="absolute inset-y-0 right-0 w-24 z-10 
            bg-gradient-to-l from-white to-transparent"></div>

          <div className="flex infinite-scroll-container overflow-hidden">
            <div 
              ref={scrollRef} 
              className="flex gap-12 items-center"
            >
              {/* Duplicate logos for seamless scroll */}
              {[...clientLogos, ...clientLogos].map((logo, index) => (
                <div
                  key={`${logo.id}-${index}`}
                  className="flex-shrink-0 w-48 h-32 bg-white 
                  rounded-xl shadow-lg flex items-center justify-center 
                  transition-all duration-300 hover:scale-105 
                  hover:shadow-xl hover:bg-blue-50/50"
                >
                  <img 
                    src={logo.logo} 
                    alt={logo.name}
                    className="max-w-full max-h-full object-contain "
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientLogo;