import React from "react";
import couple from "../../../Assets/kapil_Industry_.jpg";
import { Link } from "react-router-dom";

const Industries = ({ industriesLinks,
  setActive,
  linkClicked,
  setOpen
 }) => {

 console.log("linkclcicked",linkClicked)
  return (
    <>
      <div className="absolute top-0   xl:block hidden z-10 shadow-xl">
        {/*  services 4 parts */}
        <div className="bg-[#fff] p-5  grid grid-cols-3 gap-16 px-32">
          <div className="px-12 ">
            <div className="border-dashed  border border-light-blue-500 p-1">
              <div className="imagehover">
                <img src={couple} alt="" className="h-full" />
                <div className="overlay"></div>
              </div>
              <h1 className="text-lg  my-3 mx-2" style={{ color: "#003366" }}>
                {"Digital Excellence "}
               
              </h1>
              <p className="text-sm mb-1 mx-2 drop-shadow-lg" style={{color:"black"}}>
                {" "}
              Revolutionizing tomorrow, delivering today
              </p>
            </div>
          </div>

          <div>
            {industriesLinks.industries_1?.links?.subLink1.map((item) => (
              <li className="no-underline list-none mb-2">
                <Link
                  to={item.link}
                  className="text-sm text-gray-400  hover:text-[#4A3AFF]"
                  onClick={() => {setActive("")
                    linkClicked()}
                  }
                >
                  {item.name}
                </Link>
              </li>
            ))}{" "}
          </div>

          <div>
            {industriesLinks.industries_2?.links?.subLink1.map((item) => (
              <li className="no-underline list-none mb-2">
                <Link
                  to={item.link}
                  className="text-sm text-gray-400 my-2.5 hover:text-[#4A3AFF]"
                  onClick={() => {setActive("")
                    linkClicked()}
                  }
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </div>

          {/* <div>
            {industriesLinks.industries_3?.links?.subLink1.map((item) => (
              <li className="no-underline list-none">
                <Link
                  to={item.link}
                  className="text-xs text-gray-400 my-2.5 hover:text-[#4A3AFF]"
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </div> */}
        </div>
      </div>

      {/* mobile screen*/}

      <div className="xl:hidden block">
        <div>
          {industriesLinks.industries_1?.links?.subLink1.map((item) => (
            <li className="no-underline list-none mb-2">
              <Link
                to={item.link}
                className="text-sm text-gray-400 my-2.5 hover:text-[#4A3AFF] ml-5"
                onClick={()=> setOpen("")}
              >
                {item.name}
              </Link>
            </li>
          ))}{" "}
        </div>
        <div>
          {industriesLinks.industries_2?.links?.subLink1.map((item) => (
            <li className="no-underline list-none mb-2">
              <Link
                to={item.link}
                className="text-sm text-gray-400 my-2.5 hover:text-[#4A3AFF] ml-5"
                onClick={()=> setOpen("")}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </div>

      </div>
    </>
  );
};

export default Industries;
